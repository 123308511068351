import {CodebookItemDto} from '@finanso/api-common';

type CbDocumentTypeAttributes =
    | 'HYPOS_SCAN_TYPE'
    | 'HYPOS_TEMPLATE_ID'
    | 'DOCUMENT_GROUP_ID'
    | 'UPLOADER_ALLOWED'
    | 'UPLOADER_CLIENT_TITLE'
    | 'UPLOADER_CLIENT_DESCRIPTION'
    | 'UPLOADER_CUSTOM_TEMPLATE_ALLOWED'
    | 'UPLOADER_CLIENT_FORM_URL'
    | 'UPLOAD_TO_VALUATION_ORDER'
    | 'INCOME_COMMENT_REQUIRED'
    | 'INCOME_DOCUMENT_TYPE'
    | 'INCOME_DOCUMENT_IGNORE'
    | 'ALWAYS_DISPLAY_IN_BO'
    | 'LABEL_NAME'
    | 'SIGN_OBJECT_TYPE'
    | 'JOINER_ALLOWED'
    | 'JOINER_PICS_PER_PAGE'
    | 'JOINER_MAX_MB_SIZE'
    | 'CONTRACT_CONSTRUCTION_TYPE';

export interface CbDocumentType extends CodebookItemDto {
    attributes: Record<CbDocumentTypeAttributes, string>;
}

export enum ServerCodeBookIds {
    FIXATION = 'cbFixation',
    SERVICE_TYPE = 'cbServiceType',
    CHANNEL = 'cbChannel',
    APPLICANT_ROLE = 'cbApplicantRole',
    IDENTITY_DOCUMENT_TYPE = 'cbIdentityDocumentType',
    APPLICATION_PHASE = 'cbApplicationPhase',
    ACTIVITY_TYPE = 'cbActivityType',
    APPLICATION_STATE = 'cbApplicationState',
    RELATION_TO_APPLICANT = 'cbRelationToApplicant',
    INSURANCE_TYPE = 'cbInsuranceType',
    INCOME_SOURCE = 'cbIncomeSource',
    MARITAL_STATUS = 'cbMaritalStatus',
    NATIONALITY = 'cbNationality',
    CONSTRUCTION_MATERIAL_FAMILY_HOUSE = 'cbConstructionMaterialFamilyHouse',
    CONSTRUCTION_MATERIAL_APARTMENT_BUILDING = 'cbConstructionMaterialApartmentBuilding',
    PURPOSE_OF_USE_FAMILY_HOUSE = 'cbPurposeOfUseFamilyHouse',
    PURPOSE_OF_USE_APARTMENT = 'cbPurposeOfUseApartment',
    TECHNICAL_CONDITION_FAMILY_HOUSE = 'cbTechnicalConditionFamilyHouse',
    TECHNICAL_CONDITION_APARTMENT = 'cbTechnicalConditionApartment',
    TECHNICAL_CONDITION_APARTMENT_BUILDING = 'cbTechnicalConditionApartmentBuilding',
    FAMILY_HOUSE_TYPE = 'cbTypeFamilyHouse',
    BANK = 'cbBank',
    KYC_REQUEST_TYPE = 'cbKycRequestType',
    KYC_DOCUMENT_TYPE = 'cbKycDocumentType',
    DAY_TIME = 'cbDayTime',
    CB_CONTRACT_CONDITION_TYPE = 'cbContractConditionType',
    EMPLOYMENT_TYPE = 'cbEmploymentType',
    CZ_NACE = 'cbCzNace',
    EDUCATION = 'cbEducation',
    LIVING_TYPE = 'cbLivingType',
    COST_ACCOUNTING = 'cbCostAccounting',
    COST_RATE = 'cbCostRate',
    EXPENSES_EVIDENCE_TYPE = 'cbExpensesEvidenceType',
    COMMUNITY_PROPERTY = 'cbCommunityProperty',
    PROPERTY_ITEM_TYPE = 'cbPropertyItemType',
    TYPE_FAMILY_HOUSE = 'cbTypeFamilyHouse',
    LOAN_PURPOSE = 'cbLoanPurpose',
    DOCUMENT_TYPE = 'cbDocumentType',
    CB_APARTMENT_DISPOSITION = 'cbApartmentDisposition',
    CB_LOAN_OBJECT_TYPE = 'cbLoanObjectType',
    CB_EXTERNAL_RESOURCE_TYPE = 'cbExternalResourcesType',
    CB_OWN_RESOURCE_TYPE = 'cbOwnResourcesType',
}

export enum CbBonityTypeEnum {
    FULL = 'FULL',
    SIMPLE = 'SIMPLE',
}

export enum CbRelationToApplicantEnum {
    marriage = 'MARRIAGE',
    partner = 'PARTNER',
    parent = 'PARENT',
    child = 'CHILD',
    sibling = 'SIBLING',
    coOwner = 'CO_OWNER',
    notAvailable = 'NOT_AVAILABLE',
    parentInLaw = 'PARENT_IN_LAW',
    childInLaw = 'CHILD_IN_LAW',
    siblingInLaw = 'SIBLING_IN_LAW',
    grandparent = 'GRANDPARENT',
    grandchild = 'GRANDCHILD',
    other = 'OTHER',
}

export enum CbMartialStatusEnum {
    single = 'SINGLE',
    married = 'MARRIED',
    divorced = 'DIVORCED',
    widowed = 'WIDOWED',
    registeredPartnership = 'REG_PARTNERSHIP',
}

export enum CbEmploymentTypeEnum {
    permanent = 'PERMANENT',
    temporary = 'TEMPORARY',
    work = 'WORK',
    oneTimeJob = 'ONE_TIME_JOB',
}

export enum CbIncomeSourceEnum {
    employment = 'EMPLOYMENT', // Zaměstnání
    entrepreneur = 'ENTREPRENEUR', // Podníkání
    familyLeave = 'FAMILY_LEAVE', // Rodicovska
    maternityLeave = 'MATERNITY_LEAVE', // Materska
    other = 'OTHER', // Jiné
    alimony = 'ALIMONY',
    capitalGains = 'CAPITAL_GAINS',
    fosterCare = 'FOSTER_CARE',
    allowance = 'ALLOWANCE',
    intDriverDiets = 'INT_DRIVER_DIETS',
    disabilityPension = 'DISABLITY_PENSION',
    retirementPension = 'RETIREMENT_PENSION',
    otherPension = 'OTHER_PENSION',
    profitShare = 'PROFIT_SHARE',
    rentalNoDap = 'RENTAL_NO_DAP',
    rentalDapBeforeTax = 'RENTAL_DAP_BEFORE_TAX',
    rentalDapAfterTax = 'RENTAL_DAP_AFTER_TAX',
}

export enum CbCostRate {
    _40 = '40',
    _60 = '60',
    _80 = '80',
}

export enum CbCostAccounting {
    accountancy = 'ACCOUNTANCY',
    costRate = 'COST_RATE',
    taxRecords = 'TAX_RECORDS',
    flatTax = 'FLAT_TAX',
}

export enum CbRelationToApplicant {
    marriage = 'MARRIAGE',
    partner = 'PARTNER',
    parent = 'PARENT',
    child = 'CHILD',
    sibling = 'SIBLING',
    coOwner = 'CO_OWNER',
    notAvailable = 'NOT_AVAILABLE',
    parentInLaw = 'PARENT_IN_LAW',
    childInLaw = 'CHILD_IN_LAW',
    siblingInLaw = 'SIBLING_IN_LAW',
    grandparent = 'GRANDPARENT',
    grandchild = 'GRANDCHILD',
    other = 'OTHER',
}

export enum CbApplicantRole {
    applicant = 'APPLICANT',
    coApplicant = 'COAPPLICANT',
}

export enum CbMilestoneStatus {
    NOT_STARTED = 'NOT_STARTED',
    OPENED = 'OPENED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    INVALIDATED = 'INVALIDATED',
    WAITING_DOCS = 'WAITING_DOCS',
}

export enum CbZenIdStatus {
    DATA_MISMATCH = 'DATA_MISMATCH',
}

export enum CbKycBankIdState {
    ok = 'OK',
    nok = 'NOK',
    checksOkDifferentPerson = 'CHECKS_OK_DIFFERENT_PERSON',
    error = 'ERROR',
    keyDataChanged = 'KEY_DATA_CHANGED',
}

export enum CbDocumentTypeEnum {
    accountStatement = 'ACCOUNT_STATEMENT',
    accountStatementCoa = 'ACCOUNT_STATEMENT_COAPPLICANT',
    draftContract = 'DRAFT_CONTRACT',
    finalContract = 'FINAL_CONTRACT',
    oldLoanAgreement = 'OLD_LOAN_AGREEMENT',
    otherPropertyDocuments = 'PROPERTY_PHOTO_AUX',
    personalPaymentInsuranceShort = 'PERSONAL_PAYMENT_INSURANCE_SHORT',
    personalPaymentInsurance = 'PERSONAL_PAYMENT_INSURANCE',
    preContractualDocument = 'PRE_CONTRACTUAL_DOCUMENT',
    propertyDocument = 'PROPERTY_DOCUMENT',
    propertyPhoto = 'PROPERTY_PHOTO',
    propertyValuationSummary = 'PROPERTY_VALUATION_SUMMARY',
    propertyPhotoAux = 'PROPERTY_PHOTO_AUX',
    disbursementRequest = 'DISBURSEMENT_REQUEST',
    propertyCertificate = 'PROPERTY_CERTIFICATE',
    creditorApproval = 'CREDITOR_APPROVAL',
    creditorCalculation = 'CREDITOR_CALCULATION',
    propertyInsurance = 'PROPERTY_INSURANCE',
    propertyCertificateNoLimitations = 'PROPERTY_CERTIFICATE_NO_LIMITATIONS',
    propertyCertificateCollateralRegistered = 'PROPERTY_CERTIFICATE_COLLATERAL_REGISTERED',
    insuranceLien = 'INSURANCE_LIEN',
    taxReturnForm = 'TAX_RETURN_FORM',
    taxReturnPaidProof = 'TAX_RETURN_PAID_PROOF',
    emplAccountStatement = 'EMPL_ACCOUNT_STATEMENT',
    emplIncomeProof = 'EMPL_INCOME_PROOF',
    emplEmployerLinks = 'EMPL_EMPLOYER_LINKS',
    maternityLeaveAccountStatement = 'MATERNITY_LEAVE_ACCOUNT_STATEMENT',
    maternityLeaveProof = 'MATERNITY_LEAVE_PROOF',
    maternityLeavePostProof = 'MATERNITY_LEAVE_POST_PROOF',
    parentalLeaveAccountStatement = 'PARENTAL_LEAVE_ACCOUNT_STATEMENT',
    parentalLeaveProof = 'PARENTAL_LEAVE_PROOF',
    parentalLeavePostProof = 'PARENTAL_LEAVE_POST_PROOF',
    propertyRentAgreement = 'PROPERTY_RENT_AGREEMENT',
    currentAccountLinkAgreement = 'CURRENT_ACCOUNT_LINK_AGREEMENT',
    taxReturnFormPrevious = 'TAX_RETURN_FORM_PREVIOUS',
    reservationAgreement = 'RESERVATION_AGREEMENT',
    escrowNotaryAgreement = 'ESCROW_NOTARY_AGREEMENT',
    escrowBankAgreement = 'ESCROW_BANK_AGREEMENT',
    escrowBrokerAgreement = 'ESCROW_BROKER_AGREEMENT',
    salesContract = 'SALES_CONTRACT',
    escrowAgreement = 'ESCROW_AGREEMENT',
    agreementFutureAgreement = 'AGREEMENT_FUTURE_AGREEMENT',
}

export enum CbApplicationState {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    MANUAL = 'MANUAL',
    HANDED_OVER = 'HANDED_OVER',
    SEMI_MANUAL = 'SEMI_MANUAL',
}

export enum CbCommunityProperty {
    COMMUNITY_PROPERTY = 'COMMUNITY_PROPERTY',
    LIMITED_COMMUNITY_PROPERTY = 'LIMITED_COMMUNITY_PROPERTY',
}

export enum CbPropertyItemType {
    APARTMENT = 'APARTMENT',
    LAND = 'LAND',
    HOUSE = 'HOUSE',
    OTHER = 'OTHER',
    RECREATIONAL_OBJECT = 'RECREATIONAL_OBJECT',
}

export enum CbCuzkBuildingUsageType {
    APARTMENT_BUILDING = 'APARTMENT_BUILDING',
    FAMILY_HOUSE = 'FAMILY_HOUSE',
    RESIDENTIAL_BUILDING = 'RESIDENTIAL_BUILDING',
}

export enum CbLoanPurpose {
    marriageSettlement = 'MARRIAGE_SETTLEMENT',
    inheritanceSettlement = 'INHERITANCE_SETTLEMENT',
    topup = 'TOPUP',
    purchaseDeveloper = 'PURCHASE_DEVELOPER',
    refinance = 'REFINANCE',
    other = 'OTHER',
    purchase = 'PURCHASE',
    build = 'BUILD',
    reconstruct = 'RECONSTRUCT',
}

export enum CbLoanObjectType {
    houseLand = 'HOUSE_LAND',
    flat = 'FLAT',
    cooperativeFlat = 'COOPERATIVE_FLAT',
    recreationalBuilding = 'RECREATIONAL_BUILDING',
    plot = 'PLOT',
    other = 'OTHER',
}

export enum CbOwnResourcesType {
    other = 'OTHER',
}

