import {Icon, TextInput as TextInputDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps, FormEvent, useEffect, useRef, useState} from 'react';
import {useFormikField} from '../../hook';
import {formatOnPaste, formatPersonalNumber} from '../../utils';
import {personalNumberMask} from '../../utils/configureMask';
import {getErrorMessage} from './_helpers';
import {TextInput} from './TextInput';
import {conformToMask} from 'react-text-mask';

export const PersonalNumberInput = (props: ComponentProps<typeof TextInput>) => {
    const [field, meta, , context] = useFormikField<string>(props.name);

    const [textInputValue, setTextInputValue] = useState('');
    const hasChangedRef = useRef<boolean>(false);

    useEffect(() => {
        if (Number.isNaN(field.value)) {
            return;
        }

        if (!hasChangedRef.current) {
            if (field.value === null || field.value === '') {
                setTextInputValue('');
            } else {
                setTextInputValue(conformToMask(field.value, personalNumberMask, {}).conformedValue);
            }
        }
        hasChangedRef.current = false;
    }, [field.value]);

    const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
        hasChangedRef.current = true;
        setTextInputValue(e.currentTarget.value);
        field.onChange({
            target: {
                name: props.name,
                value: e.currentTarget.value.replace(/\s/g, ''),
            },
        });

        validateField();
    };

    const handleOnBlur = (e: FormEvent<HTMLInputElement>) => {
        field.onBlur(e);
        validateField();
    };

    const validateField = () => {
        // Formik sets isValidating incorrectly when asyncValidation used, hence the timeout
        setTimeout(() => {
            context.validateField(props.name);
        });
    };

    const onBeforeInput = (inputEvent: any) => {
        const newChar = inputEvent.data || '';
        const isNumber = newChar.match(/^\d+$/);
        const value = inputEvent?.target?.value || '';
        const valueHasSlash = value.indexOf('/') !== -1;

        // Accept only if new char is a number or slash and slash is not the in input value
        if (isNumber || (newChar === '/' && !valueHasSlash && value.length === 6)) {
            return true;
        }

        inputEvent.preventDefault();
        inputEvent.stopPropagation();
        return false;
    };

    const textProps = {
        ...field,
        ...props,
        onBeforeInput,
        value: textInputValue,
        onChange: handleOnChange,
        onPaste: formatOnPaste((value) => formatPersonalNumber(value, '/')),
        onBlur: handleOnBlur,
        mask: personalNumberMask,
        type: 'tel',
        error: getErrorMessage(context, meta),
        suffix: field.value && context.isValidating ? <Icon name={'loading'} /> : undefined,
        isDisabled: props.isDisabled || context.isSubmitting,
    };

    return <TextInputDs {...textProps} />;
};
