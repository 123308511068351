import {Alert, Button, Col, Icon, Row, Text} from '@mmb-digital/ds-lilly';
import React, {useContext} from 'react';
import {ConfigContext} from '../../context';
import {useToggler} from '../../hook';
import {c, themeNames} from '../../utils';

export const SystemErrorMessageBar = () => {
    const {systemErrorMessage} = useContext(ConfigContext);
    const [closed, close] = useToggler();

    return systemErrorMessage && !closed ? (
        <Alert theme={c(themeNames.mb['0'])} type={'error'}>
            <Row theme={c(themeNames.justify.between, themeNames.mb['0'])}>
                <Col>
                    <Text isTextCenter={true}> {systemErrorMessage}</Text>
                </Col>
                <Col xs={'auto'}>
                    <Button isSmall={true} type={'transparent'} isRounded={true} theme={themeNames.p['0']} onClick={close}>
                        <Icon name={'close'} />
                    </Button>
                </Col>
            </Row>
        </Alert>
    ) : null;
};
