import {MortgageCalculationRequest, MortgageDto, OffsetVariantDto} from '@finanso/api-common';

export const convertOffsetToMortgage = (offset: OffsetVariantDto, mortgage: MortgageCalculationRequest): MortgageDto => ({
    amountMortgage: mortgage.amountMortgage,
    term: mortgage.term,
    declaredRealtyValue: mortgage.realtyAmount,
    totalAmountPayable: offset.totalAmountPayable,
    insuranceInstallment: offset.monthlyInsuranceInstallment,
    rpsn: offset.rpsn,
    installment: offset.monthlyInstallment,
    insurance: offset.insurance,
    // @ts-ignore
    cbFixation: offset.cbFixation,
    // @ts-ignore
    rate: offset.annualInterestRate,
});

export const getRealtyAmount = (mortgage?: MortgageDto) => mortgage?.finalRealtyValue || mortgage?.declaredRealtyValue;
