export const MAX_PRECISION = 0.01;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_SPACES = 'DD.\xa0MM.\xa0YYYY';
export const DATE_FORMAT_DAY_LETTER = 'D';
export const DATE_FORMAT_MONTH_LETTER = 'M';
export const DATE_FORMAT_YEAR_LETTER = 'Y';
export const DATE_FORMAT_SEPARATOR = '.';
export const SUPPORTED_DATES_REGEX = new RegExp(
    `^\\s*\\d{1,2}\\s*\\${DATE_FORMAT_SEPARATOR}\\s*\\d{1,2}\\s*\\${DATE_FORMAT_SEPARATOR}\\s*\\d{4}\\s*$`
);

export const PHONE_NUMBER_PREFIX = '+420';
export const PHONE_NUMBER_PREFIX_WITHOUT_PLUS = '420';

export const IMAGE_JOINER_ALLOWED_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

export const DEFAULT_LTV_RATIO = 0.9;
export const CURRENCY_SUFFIX = 'Kč';

export const AMOUNT_MIN_VALUE = 300_000;
export const AMOUNT_MAX_VALUE = 30_000_000;

export const REALTY_MIN_VALUE = 335_000;
export const REALTY_MAX_VALUE = 38_000_000;

export const INVESTMENT_AMOUNT_MIN: number = 1;
export const INVESTMENT_EXTERNAL_AMOUNT_MIN = 0;
export const INVESTMENT_AMOUNT_MAX = 50_000_000;

