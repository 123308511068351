import {CodebookDto, RateDto} from '@finanso/api-common';
import {Col, Icon, Row, Text} from '@mmb-digital/ds-lilly';
import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import {usePrevious} from 'react-use';
import {CURRENCY_SUFFIX} from '../../constants';
import {c, themeNames} from '../../utils';
import {BrickInput, BrickInputProps, IntegerInput} from '../form';
import {Percent} from '../Percent/Percent';
import {MortgageFormDataProps} from './MortgageForm.schema';
import {PpiInfoPopup} from './PpiInfoPopup';

const YesNoBricks = [
    {label: 'Ano', value: true},
    {label: 'Ne', value: false},
];

const YesNoBricksPreventAddingPpi = [
    {label: 'Ano', value: true, isDisabled: true},
    {label: 'Ne', value: false},
];

export const MortgageFormFields = ({
    rates,
    fixations,
    disabled,
    preventPpiAdding = false,
    buttonsSmall = false,
}: {
    rates?: RateDto[];
    fixations?: CodebookDto;
    disabled?: boolean;
    preventPpiAdding?: boolean;
    buttonsSmall?: boolean;
}) => {
    const [bricks, setBricks] = useState<BrickInputProps['bricks'] | undefined>();
    const [showPpi, setShowPpi] = useState<boolean>(false);

    const handleShowPpiPopup = useCallback(() => {
        setShowPpi(true);
    }, []);

    const handleClosePpiPopup = useCallback(() => {
        setShowPpi(false);
    }, []);

    const {values} = useFormikContext<MortgageFormDataProps>();

    const newInsurance = values.insurance;
    const oldInsurance = usePrevious(newInsurance);

    useEffect(() => {
        if (!newInsurance && oldInsurance) {
            handleShowPpiPopup();
        } else if (newInsurance && !oldInsurance) {
            handleClosePpiPopup();
        }
    }, [newInsurance, oldInsurance]);

    useEffect(() => {
        if (fixations) {
            setBricks(
                fixations.codebookItemDtos?.map((fixation) => {
                    const rate =
                        rates && rates.find((r) => fixation.attributes && fixation.attributes.VALUE && r.fixation === +fixation.attributes.VALUE);

                    return {
                        value: fixation.code,
                        label: (
                            <Row theme={c('flex-column', themeNames.mb['0'])}>
                                <Col>
                                    <Text weight={'bold'}>{fixation.text}</Text>
                                </Col>
                                {rate && (
                                    <Col>
                                        <Text size={'small'}>
                                            <Percent value={rate.rate || ''} />
                                        </Text>
                                    </Col>
                                )}
                            </Row>
                        ),
                    };
                })
            );
        }
    }, [fixations, rates]);

    return (
        <>
            {!values.hideRealtyAmount && (
                <Row theme={c(themeNames.justify.between, themeNames.pt.large)}>
                    <Col xs={12} md={6}>
                        <Text>Hodnota nemovitosti</Text>
                    </Col>
                    <Col xs={12} md={6}>
                        <IntegerInput name={'realtyAmount'} afterControl={CURRENCY_SUFFIX} isDisabled={disabled} />
                    </Col>
                </Row>
            )}
            <Row theme={c(themeNames.justify.between, !values.hideRealtyAmount ? '' : themeNames.pt.large)}>
                <Col xs={12} md={6}>
                    <Text>Chci si půjčit</Text>
                </Col>
                <Col xs={12} md={6}>
                    <IntegerInput name={'amount'} afterControl={CURRENCY_SUFFIX} isDisabled={disabled} />
                </Col>
            </Row>
            <Row theme={themeNames.justify.between}>
                <Col xs={12} md={6}>
                    <Text>Chci splatit za</Text>
                </Col>
                <Col xs={12} md={6}>
                    <IntegerInput name={'term'} afterControl={'let'} withArrows={true} min={5} max={30} isDisabled={disabled} />
                </Col>
            </Row>
            <Row theme={c(themeNames.justify.between, themeNames.align.center)}>
                <Col xs={6} lg={buttonsSmall ? 3 : 2}>
                    <Text>Fixace</Text>
                </Col>
                <Col xs="auto" theme={themeNames.pr.large}>
                    <BrickInput
                        name={'fixation'}
                        bricks={bricks || []}
                        isDisabled={disabled}
                        isInline={buttonsSmall}
                        isBrickSmall={buttonsSmall}
                        isWidthAuto={buttonsSmall}
                    />
                </Col>
            </Row>
            <Row theme={c(themeNames.justify.between, themeNames.mb['0'])}>
                <Col xs={6}>
                    <span onClick={showPpi ? handleClosePpiPopup : handleShowPpiPopup}>
                        <Text theme={'u-text-pointer'}>
                            Pojištění <Icon name="info" backgroundColor="secondaryNormal" isRight={true} size={'small'} />
                        </Text>
                    </span>
                </Col>
                <Col xs={'auto'} theme={themeNames.mr.xSmall}>
                    {showPpi && <PpiInfoPopup onClose={handleClosePpiPopup} />}
                    <BrickInput
                        name={'insurance'}
                        bricks={preventPpiAdding ? YesNoBricksPreventAddingPpi : YesNoBricks}
                        isDisabled={disabled}
                        isBrickSmall={buttonsSmall}
                    />
                </Col>
            </Row>
        </>
    );
};

