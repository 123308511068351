import React from 'react';
import NumberFormat, {NumberFormatProps} from 'react-number-format';

import {renderToStaticMarkup} from 'react-dom/server';
import {CURRENCY_SUFFIX} from '../../constants';

const DEFAULT_CURRENCY_PROPS: NumberFormatProps = {
    displayType: 'text',
    thousandSeparator: ' ',
    decimalSeparator: ',',
    suffix: ` ${CURRENCY_SUFFIX}`,
};

export const formatCurrencyWithNbps = (props: NumberFormatProps) => {
    const renderTextFn = (formattedValue: string) => formattedValue.toString();
    const value = renderToStaticMarkup(<NumberFormat {...DEFAULT_CURRENCY_PROPS} {...props} displayType="text" renderText={renderTextFn} />);

    if (value) {
        return value.replace(/\s/g, '\u00A0');
    }

    return value;
};

export const Currency = (props: NumberFormatProps) => {
    if (props.value === undefined || props.value === null) {
        return null;
    }

    return <NumberFormat {...DEFAULT_CURRENCY_PROPS} {...props} />;
};
