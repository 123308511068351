export const c = (...args: string[]) => {
    return args.filter((a) => !!a).join(' ');
};

export const themeNames = {
    p: {
        '0': 'u-p--0',
        xxLarge: 'u-p--large',
        medium: 'u-p--medium',
    },
    pb: {
        '0': 'u-pb--0',
        small: 'u-pb--small',
        xSmall: 'u-pb--xSmall',
        xxSmall: 'u-pb--xxSmall',
        xxxSmall: 'u-pb--xxxSmall',
        medium: 'u-pb--medium',
        large: 'u-pb--large',
        xxLarge: 'u-pb--xxLarge',
        xxxLarge: 'u-pb--xxxLarge',
        md: {
            '0': 'u-pb-md--0',
        },
    },
    pt: {
        '0': 'u-pt--0',
        small: 'u-pt--small',
        xSmall: 'u-pt--xSmall',
        xxSmall: 'u-pt--xxSmall',
        xxxSmall: 'u-pt--xxxSmall',
        medium: 'u-pt--medium',
        large: 'u-pt--large',
        xxLarge: 'u-pt--xxLarge',
        xxxLarge: 'u-pt--xxxLarge',
    },
    pl: {
        '0': 'u-pl--0',
        large: 'u-pl--large',
        xLarge: 'u-pl--xLarge',
        xxLarge: 'u-pl--xxLarge',
        small: 'u-pl--small',
        xSmall: 'u-pl--xSmall',
        xxSmall: 'u-pl--xxSmall',
        xxxSmall: 'u-pl--xxxSmall',
    },
    pr: {
        '0': 'u-pr--0',
        large: 'u-pr--large',
        small: 'u-pr--small',
        xSmall: 'u-pr--xSmall',
        xxSmall: 'u-pr--xxSmall',
        xxxSmall: 'u-pr--xxxSmall',
        xxxLarge: 'u-pr--xxxLarge',
    },
    px: {
        '0': 'u-px--0',
        xLarge: 'u-px--xLarge',
        xxLarge: 'u-px--xxLarge',
    },
    mb: {
        '0': 'u-mb--0',
        large: 'u-mb--large',
        xxLarge: 'u-mb--xxLarge',
        small: 'u-mb--small',
        xSmall: 'u-mb--xSmall',
        xxSmall: 'u-mb--xxSmall',
    },
    mt: {
        '0': 'u-mt--0',
        small: 'u-mt--small',
        xxSmall: 'u-mt--xxSmall',
        large: 'u-mt--large',
        xLarge: 'u-mt--xLarge',
        xxLarge: 'u-mt--xxLarge',
    },
    mr: {
        '0': 'u-mr--0',
        large: 'u-mr--large',
        small: 'u-mr--small',
        xSmall: 'u-mr--xSmall',
        xxSmall: 'u-mr--xxSmall',
        xxxSmall: 'u-mr--xxxSmall',
    },
    ml: {
        '0': 'u-ml--0',
        large: 'u-ml--large',
        small: 'u-ml--small',
        xSmall: 'u-ml--xSmall',
        xxSmall: 'u-ml--xxSmall',
        xxxSmall: 'u-ml--xxxSmall',
    },
    justify: {
        center: 'justify-content-center',
        between: 'justify-content-between',
        end: 'justify-content-end',
    },
    align: {
        start: 'align-items-start',
        center: 'align-items-center',
        end: 'align-items-end',
        self: {
            start: 'align-self-start',
            center: 'align-self-center',
            end: 'align-self-end',
        },
    },
    direction: {
        column: 'flex-column',
        row: 'flex-row',
    },
    h: {
        100: 'h-100',
    },
    typography: {
        center: 'u-textCenter',
        left: 'u-textLeft',
        right: 'u-textRight',
    },
};
