import {MortgageDto} from '@finanso/api-common';
import {Flex, Row, Text} from '@mmb-digital/ds-lilly';
import React from 'react';
import {c, themeNames} from '../../utils/styles/classes';
import {Currency} from '../Currency';
import {LabelValue, LabelValueProps} from '../LabelValue/LabelValue';
import {Percent} from '../Percent/Percent';
import {RpsnExtraInfoContent} from './RpsnExtraInfoContent';

import '../../styles/MortgageFormSummary.scss';
import {TooltipMoreInfoButton} from './TooltipMoreInfoButton';
import {getRealtyAmount} from './utils';

const defaultLabelValueProps: Partial<LabelValueProps> = {
    withColon: false,
    valueProps: {
        isTextRight: true,
    },
    theme: c('MortgageFormSummary-row', themeNames.pb.xSmall, themeNames.pt.xSmall),
};

export const MortgageFormSummary = ({mortgage}: {mortgage: MortgageDto}) => {
    const realtyAmount = getRealtyAmount(mortgage);
    return (
        <Row theme={c('flex-column', themeNames.pt.small)}>
            <LabelValue
                {...defaultLabelValueProps}
                label="Měsíční splátka úvěru"
                labelTooltip={
                    <Text>
                        Uvedená měsíční splátka hypotéky nezahrnuje částku za pojištění schopnosti splácet. Splátka za pojištění je 8,99% z měsíční
                        splátky
                    </Text>
                }
                value={mortgage?.installment && <Currency value={mortgage.installment} />}
                valueProps={{
                    ...defaultLabelValueProps.valueProps,
                    theme: 'u-text-xxLarge',
                    weight: 'bold',
                }}
            />
            <LabelValue {...defaultLabelValueProps} label={'Hodnota nemovitosti'} value={realtyAmount && <Currency value={realtyAmount} />} />
            <LabelValue {...defaultLabelValueProps} label={'Roční úroková sazba'} value={mortgage?.rate && <Percent value={mortgage.rate} />} />
            <LabelValue
                {...defaultLabelValueProps}
                label={'RPSN'}
                labelTooltipTrigger={'click'}
                labelTooltip={
                    <Text>
                        <Flex direction={'column'} alignItems={'center'}>
                            Roční procentní sazba nákladů představuje celkové náklady vyjádřené jako roční procento z celkové výše úvěru.{' '}
                            <TooltipMoreInfoButton
                                content={<RpsnExtraInfoContent />}
                                title={<Text isTextCenter={true}>Roční procentní sazba nákladů</Text>}
                            />
                        </Flex>
                    </Text>
                }
                value={mortgage?.rpsn && <Percent value={mortgage.rpsn} />}
            />
            {mortgage?.insurance && (
                <LabelValue
                    {...defaultLabelValueProps}
                    label={'Měsíční cena pojištění'}
                    value={mortgage?.insuranceInstallment && <Currency value={mortgage.insuranceInstallment} />}
                />
            )}
            <LabelValue
                {...defaultLabelValueProps}
                label={'Celkem zaplatíte'}
                value={mortgage?.totalAmountPayable && <Currency value={mortgage.totalAmountPayable} />}
            />
        </Row>
    );
};

