import * as Sentry from '@sentry/react';
import {ErrorBoundaryProps} from '@sentry/react/dist/errorboundary';
import React, {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import '@mmb-digital/ds-lilly/dist/ds-lilly.css';
import '@finanso/common/dist/common.cjs.production.min.css';
import './App.scss';
import {ApiProvider, BackdropLoader} from '@finanso/common';
import {storageService} from './utils/storage';

const FinansoApp = React.lazy(() => import('./FinansoApp'));

const initSentry = () => {
    try {
        if (window.finansoConfig?.sentry?.dsn) {
            Sentry.init({
                dsn: window.finansoConfig?.sentry?.dsn,
                environment: window.finansoConfig?.sentry?.env,
                release: window.finansoConfig?.sentry?.release,
            });
        }
    } catch (e) {
        // continue without sentry
        console.warn(e);
    }
};

const handleOnApiProviderInit = (data: any) => {
    window.finansoConfig = data;
    initSentry();
};

const enhanceScopeWithClientInfo: ErrorBoundaryProps['beforeCapture'] = (scope) => {
    scope.setExtra('applicationUid', storageService.getItem('applicationUid'));
    scope.setExtra('clientUid', storageService.getItem('clientUid'));

    return scope;
};

export function AppRoot() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <ApiProvider onInit={handleOnApiProviderInit} fallback={<BackdropLoader isLoading={true} isFullScreen={true} />}>
                <Sentry.ErrorBoundary beforeCapture={enhanceScopeWithClientInfo}>
                    <Suspense fallback={<BackdropLoader isLoading={true} isFullScreen={true} />}>
                        <FinansoApp />
                    </Suspense>
                </Sentry.ErrorBoundary>
            </ApiProvider>
        </Router>
    );
}
