import {Icon, Tooltip} from '@mmb-digital/ds-lilly';
import React, {ReactElement} from 'react';
import {RFC} from '../../types';
import {InfoModalControl} from './InfoModal';

export const InfoTooltip: RFC<{
    isLong?: boolean;
    title?: string | ReactElement;
}> = ({children, isLong, title}) => {
    if (!children) {
        return null;
    }

    if (isLong) {
        return <InfoModalControl title={title}> {children}</InfoModalControl>;
    }

    return (
        <Tooltip trigger="hover" content={children}>
            <span>
                <Icon name="info" backgroundColor="secondaryNormal" isRight size={'small'} />
            </span>
        </Tooltip>
    );
};
