import {Icon, ModalContent, ModalHeader, useModal} from '@mmb-digital/ds-lilly';
import React, {ReactElement} from 'react';
import {themeNames} from '../..';
import {RFC} from '../../types';

export const InfoModalControl: RFC<{title?: string | ReactElement}> = ({children, title}) => {
    const {Modal, show, modalProps} = useModal();
    return (
        <>
            <span onClick={show}>
                <Icon name="info" backgroundColor="secondaryNormal" isRight size={'small'} />
            </span>
            <Modal {...modalProps}>
                <ModalHeader theme={themeNames.p['0']} title={title ?? ''} />
                <ModalContent theme={themeNames.pb.medium}>{children}</ModalContent>
            </Modal>
        </>
    );
};
