import {TextInput as TextInputDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps, FormEvent, useEffect, useRef, useState} from 'react';
import {PHONE_NUMBER_PREFIX, PHONE_NUMBER_PREFIX_WITHOUT_PLUS} from '../../constants';
import {useFormikField} from '../../hook';
import {formatOnPaste} from '../../utils';
import {getPhoneNumberMask, phoneNumberMask, phoneNumberMaskForeign} from '../../utils/configureMask';
import {getErrorMessage} from './_helpers';
import {TextInput} from './TextInput';
import {conformToMask} from 'react-text-mask';

import '../../styles/PhoneNumberInput.scss';

const formatNumber = (value: string, disablePrefix?: boolean): string => {
    if (!value) {
        return value;
    }

    return value
        .replace(/\s/g, '')
        .replace(/\+/g, '')
        .replace(/^0+/, '')
        .replace(!disablePrefix ? PHONE_NUMBER_PREFIX_WITHOUT_PLUS : '', '');
};

export const PhoneNumberInput = (props: ComponentProps<typeof TextInput>) => {
    const [field, meta, , context] = useFormikField<string>(props.name);

    const [textInputValue, setTextInputValue] = useState(formatNumber(meta.initialValue || '', props.allowForeignPhoneNumber));
    const hasChangedRef = useRef<boolean>(false);
    useEffect(() => {
        if (Number.isNaN(field.value)) {
            return;
        }

        if (!hasChangedRef.current) {
            if (field.value === null || field.value === '') {
                setTextInputValue('');
            } else {
                setTextInputValue(
                    conformToMask(
                        formatNumber(field.value, props.allowForeignPhoneNumber),
                        props.allowForeignPhoneNumber ? phoneNumberMaskForeign : phoneNumberMask,
                        {}
                    ).conformedValue
                );
            }
        }
        hasChangedRef.current = false;
    }, [field.value, props.allowForeignPhoneNumber]);

    const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
        hasChangedRef.current = true;
        setTextInputValue(e.currentTarget.value);
        field.onChange({
            target: {
                name: props.name,
                value: props.allowForeignPhoneNumber
                    ? e.currentTarget.value.replace(/\s/g, '')
                    : e.currentTarget.value.replace(PHONE_NUMBER_PREFIX, '').replace(/\s/g, ''),
            },
        });
    };

    const textProps = {
        ...field,
        ...props,
        value: textInputValue,
        onChange: handleOnChange,
        onPaste: formatOnPaste((value) => formatNumber(value, props.allowForeignPhoneNumber)),
        mask: getPhoneNumberMask(props.allowForeignPhoneNumber),
        error: getErrorMessage(context, meta),
        inputMode: 'tel',
        type: 'tel',
        guide: true,
    };
    return (
        <TextInputDs
            {...textProps}
            prefix={field.value && !props.allowForeignPhoneNumber ? '+420 ' : undefined}
            theme={`PhoneNumberInput ${props.isDisabled ? 'PhoneNumberInput--disabled' : ''}`}
        />
    );
};
