/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    accessToken?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenDto
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    refreshToken?: string;
    /**
     * 
     * @type {Date}
     * @memberof TokenDto
     */
    refreshTokenExpiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    tokenType?: string;
}

/**
 * Check if a given object implements the TokenDto interface.
 */
export function instanceOfTokenDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TokenDtoFromJSON(json: any): TokenDto {
    return TokenDtoFromJSONTyped(json, false);
}

export function TokenDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
        'expiresIn': !exists(json, 'expires_in') ? undefined : json['expires_in'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'refreshTokenExpiresAt': !exists(json, 'refresh_token_expires_at') ? undefined : (new Date(json['refresh_token_expires_at'])),
        'tokenType': !exists(json, 'token_type') ? undefined : json['token_type'],
    };
}

export function TokenDtoToJSON(value?: TokenDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'expires_in': value.expiresIn,
        'refresh_token': value.refreshToken,
        'refresh_token_expires_at': value.refreshTokenExpiresAt === undefined ? undefined : (value.refreshTokenExpiresAt.toISOString()),
        'token_type': value.tokenType,
    };
}

