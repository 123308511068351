import moment, {Moment} from 'moment';
import React, {useCallback, useState} from 'react';
import {DatePicker as DatePickerDs, DatePickerPropsType} from '@mmb-digital/ds-lilly';
import {useFormikField} from '../../hook';
import {convertAnyToMoment, getErrorMessage} from '../..';

const getInputElementByName = (name: string): HTMLInputElement | null => {
    return document.querySelector(`input[name='${name}']`);
};

const convertValueToEvent = (value: Moment | undefined, name: string) => ({
    target: {
        name,
        value: value ? value.toDate() : undefined,
    },
});

export const createInitDateValue = (initialValue: any): Date | undefined => {
    let toSet;
    if (moment.isDate(initialValue)) {
        toSet = moment
            .utc(initialValue)
            .startOf('day')
            .add(moment().utcOffset() < 0 ? -moment().utcOffset() : 0, 'minutes')
            .toDate();
    } else if (initialValue) {
        toSet = convertAnyToMoment(initialValue).toDate();
    }

    return toSet;
};

export const DatepickerField = (
    props: Omit<DatePickerPropsType, 'value' | 'onChange'> & {
        onBlur?: (value: Date) => void;
    }
) => {
    const [field, meta, , context] = useFormikField<Date>(props.name);

    const [focus, setFocus] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const handleChange: DatePickerPropsType['onChange'] = useCallback(
        (value) => {
            const toSet = value ? convertAnyToMoment(value) : undefined;

            if (typeof value === 'string' && value === moment(field.value).format('D. M. YYYY')) {
                return;
            } else if (!Array.isArray(value) && moment(value).isSame(moment(field.value), 'day')) {
                return;
            }

            field.onChange(convertValueToEvent(toSet, props.name));
        },
        [field, props.name]
    );

    const handleOnBlur = (e: any) => {
        setTimeout(() => {
            field.onBlur(e);

            if (props.onBlur && focus && !open) {
                props.onBlur(field.value);
            }

            setFocus(false);
        });
    };

    const handleOnFocus = () => {
        setFocus(true);
    };

    const handleOnOpen = () => {
        setOpen(true);

        // needed by flatpicker to select value on first click in calendar
        getInputElementByName(props.name)?.blur();
    };

    const handleOnClose = () => {
        setOpen(false);

        // Value either select from calendar or written in input, we want to keep focus in both cases
        getInputElementByName(props.name)?.focus();
    };

    return (
        <DatePickerDs
            {...field}
            {...props}
            onChange={handleChange}
            error={getErrorMessage(context, meta)}
            settings={{
                onOpen: handleOnOpen,
                onClose: handleOnClose,
                closeOnSelect: true,
            }}
            // @ts-ignore
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
        />
    );
};
