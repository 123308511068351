/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenDto,
} from '../models/index';
import {
    TokenDtoFromJSON,
    TokenDtoToJSON,
} from '../models/index';

export interface NullUsingPOSTRequest {
    grantType: NullUsingPOSTGrantTypeEnum;
    password?: string;
    refreshToken?: string;
    username?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Method for logout
     */
    async logoutPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method for logout
     */
    async logoutPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutPOSTRaw(initOverrides);
    }

    /**
     * Method for generate access and refresh token
     */
    async nullUsingPOSTRaw(requestParameters: NullUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenDto>> {
        if (requestParameters.grantType === null || requestParameters.grantType === undefined) {
            throw new runtime.RequiredError('grantType','Required parameter requestParameters.grantType was null or undefined when calling nullUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.grantType !== undefined) {
            queryParameters['grant_type'] = requestParameters.grantType;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.refreshToken !== undefined) {
            queryParameters['refresh_token'] = requestParameters.refreshToken;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/oauth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenDtoFromJSON(jsonValue));
    }

    /**
     * Method for generate access and refresh token
     */
    async nullUsingPOST(requestParameters: NullUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenDto> {
        const response = await this.nullUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const NullUsingPOSTGrantTypeEnum = {
    Password: 'password',
    RefreshToken: 'refresh_token'
} as const;
export type NullUsingPOSTGrantTypeEnum = typeof NullUsingPOSTGrantTypeEnum[keyof typeof NullUsingPOSTGrantTypeEnum];
