import {Col, Icon, Row, Text, TextPropsType, Tooltip} from '@mmb-digital/ds-lilly';
import React, {ComponentProps, ReactNode} from 'react';
import {c, themeNames} from '../../utils';
import '../../styles/LabelValue.scss';

export type LabelValueProps = {
    label: string;
    value: ReactNode;
    withColon?: boolean;
    labelProps?: TextPropsType;
    valueProps?: TextPropsType;
    valueRequired?: boolean;
    theme?: string;
    labelTooltip?: ReactNode;
    labelTooltipTrigger?: ComponentProps<typeof Tooltip>['trigger'];
};

export const LabelValue = (props: LabelValueProps) => {
    const {labelProps = {}, withColon = true, label, value, valueProps = {}, valueRequired = false, theme, labelTooltip, labelTooltipTrigger} = props;

    if (valueRequired && (value === null || value === undefined || value === '')) {
        return null;
    }

    return (
        <Row theme={c(themeNames.mb['0'], themeNames.justify.between, theme || '')}>
            <Col xs={'auto'}>
                <Text {...labelProps} theme={c(themeNames.mb.xxSmall, labelProps.theme, 'LabelValue')}>
                    {label}
                    {labelTooltip && (
                        <Tooltip trigger={labelTooltipTrigger || 'hover'} content={labelTooltip} theme={'LabelValue-tooltip'}>
                            <span>
                                <Icon name="info" backgroundColor="secondaryNormal" isRight={true} size={'small'} />
                            </span>
                        </Tooltip>
                    )}
                    {withColon ? ':' : ''}
                </Text>
            </Col>
            <Col xs={'auto'}>
                <Text {...valueProps} theme={c(themeNames.mb.xxSmall, valueProps.theme)}>
                    {value}
                </Text>
            </Col>
        </Row>
    );
};
