import {isChrome, isEdge, isIE} from 'react-device-detect';
import {MAX_PRECISION} from '../constants';
import {round} from './functions';

const MIME_TYPE_TO_EXTENSION_MAP: Record<FileMimeType, FileExtension> = {
    'application/pdf': 'pdf',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'text/plain': 'txt',
};

export function b64ToBinary(content: string) {
    const raw = window.atob(content);
    const rawLength = raw.length;

    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

function getFileNameWithExtension(fileName: string, mimeType: FileMimeType): string {
    return fileName.endsWith(MIME_TYPE_TO_EXTENSION_MAP[mimeType]) ? fileName : `${fileName}.${MIME_TYPE_TO_EXTENSION_MAP[mimeType]}`;
}

function b64ToUrlWithMimeType(content: string, mimeType: FileMimeType) {
    return `data:${mimeType};base64, ${content}`;
}

export type FileMimeType = 'image/jpeg' | 'application/pdf' | 'image/png' | 'text/plain'; // add more mime types if needed

export type FileExtension = 'jpg' | 'png' | 'jpeg' | 'pdf' | 'txt'; // add more extensions if needed

export const getB64Opener: () => ((content: string, mimeType: FileMimeType, fileName: string) => void) | false = () => {
    if (isIE) {
        return false;
    }

    if (isEdge) {
        return (c, m, f) => {
            downloadB64ContentString(c, m, f);
        };
    }

    const w = window.open(isChrome ? 'about:blank' : '', '_blank');

    return (c, m, f) => {
        openB64ContentString(c, m, f, w || undefined);
    };
};

export function openB64ContentString(content: string, mimeType: FileMimeType, fileName: string, w?: Window) {
    if (isIE) {
        downloadB64ContentString(content, mimeType, fileName);
        return;
    }

    const newWindow = w || window.open(isChrome ? 'about:blank' : '', '_blank');
    const name = getFileNameWithExtension(fileName, mimeType);

    setTimeout(() => {
        if (newWindow) {
            try {
                newWindow.document.write(
                    `<html><body><iframe width='100%' height='100%' src=${
                        content.startsWith('blob') ? content : encodeURI(b64ToUrlWithMimeType(content, mimeType))
                    } style="border: none; position: fixed; top: 0;left:0" /></body></html>`
                );

                newWindow.document.title = name;
                newWindow.document.body.style.margin = '0';
                newWindow.document.body.style.overflow = 'hidden';
                newWindow.document.close();
            } catch (e) {
                // tslint:disable-next-line:no-empty
            }
        }
    }, 100);
}

export function downloadB64ContentString(content: string, mimeType: FileMimeType, fileName: string) {
    const name = getFileNameWithExtension(fileName, mimeType);

    const url = content.startsWith('blob') ? content : b64ToUrlWithMimeType(content, mimeType);
    const a = document.createElement('a');

    a.href = url;
    a.download = name;
    a.click();
}

export const MB_IN_BYTES = Math.pow(1024, 2);
export const formatFileSize = (fileSize: number): string =>
    `${round(fileSize / MB_IN_BYTES, MAX_PRECISION)
        .toString()
        .replace('.', ',')} MB`;
