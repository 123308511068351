import {ErrorMessageDto} from '@finanso/api-common';
import {addError, ApiDispatchType, endLoading, startLoading} from '../context';
import {defaultError} from './api';

export type ApiRequestCall = {
    dispatch: ApiDispatchType;
    silent?: boolean;
    handleError?: (error: ErrorMessageDto) => void;
    handleFinally?: () => void;
    shouldHandleError?: (error: ErrorMessageDto) => boolean;
};

export const callApi = <ResponseDto>(
    apiRequestCall: ApiRequestCall & {
        asyncFn: () => Promise<ResponseDto>;
    }
) => async () => {
    const {silent = false, handleError, shouldHandleError, dispatch, asyncFn, handleFinally} = apiRequestCall;
    try {
        if (!silent) {
            dispatch(startLoading());
        }
        return await asyncFn();
    } catch (response) {
        let error = response as ErrorMessageDto;

        if (!error?.errorCode) {
            error = defaultError;
        }

        if ((handleError && shouldHandleError === undefined) || (handleError === undefined && shouldHandleError)) {
            throw Error('In order to use handleError, shouldHandleError must be defined and vice versa.');
        }
        // TODO: if response.status === 401 AND not on login ---> redirect to login either here or in Layout
        if (shouldHandleError && shouldHandleError(error) && handleError) {
            handleError(error);
        } else {
            dispatch(addError(error));
            throw error;
        }

        return;
    } finally {
        if (!silent) {
            dispatch(endLoading());
        }
        if (handleFinally) {
            handleFinally();
        }
    }
};

export const apiWrapper = <T>(
    config: ApiRequestCall & {
        asyncFn: () => Promise<T>;
    }
) => {
    return callApi<T>(config)();
};
