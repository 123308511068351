import React from 'react';

export const One = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" stroke="#770C40" strokeWidth="2" />
        <path d="M15.7427 22H18.2495V10.022H15.751L12.6548 12.1719V14.4297L15.5933 12.3877H15.7427V22Z" fill="#770C40" />
    </svg>
);

export const Two = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" stroke="#770C40" strokeWidth="2" />
        <path
            d="M11.6006 13.6577H13.9248C13.9248 12.4873 14.6885 11.6655 15.9087 11.6655C17.0791 11.6655 17.7598 12.4458 17.7598 13.4253C17.7598 14.2554 17.436 14.8032 15.9419 16.2559L11.7417 20.3149V22H20.3828V19.9829H15.1201V19.8335L17.6104 17.4595C19.6025 15.5669 20.2168 14.604 20.2168 13.2012C20.2168 11.1841 18.5898 9.71484 16.0498 9.71484C13.4268 9.71484 11.6006 11.3252 11.6006 13.6577Z"
            fill="#770C40"
        />
    </svg>
);

export const Three = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" stroke="#770C40" strokeWidth="2" />
        <path
            d="M14.4146 16.7456H15.9336C17.2783 16.7456 18.1084 17.4263 18.1084 18.522C18.1084 19.5762 17.2202 20.29 15.9502 20.29C14.6636 20.29 13.8003 19.6343 13.7256 18.5967H11.3267C11.4346 20.8379 13.2773 22.3071 15.9834 22.3071C18.6895 22.3071 20.665 20.8213 20.665 18.7295C20.665 17.1191 19.6523 16.0566 17.9839 15.8076V15.6582C19.2705 15.3345 20.1836 14.3882 20.1919 12.9355C20.2002 11.1841 18.7227 9.71484 16.0664 9.71484C13.3687 9.71484 11.667 11.2007 11.584 13.3838H13.8999C13.9829 12.2798 14.73 11.6489 15.9419 11.6489C17.1123 11.6489 17.8179 12.3545 17.8179 13.2759C17.8179 14.272 17.0459 14.9609 15.9087 14.9609H14.4146V16.7456Z"
            fill="#770C40"
        />
    </svg>
);

export const Four = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" stroke="#770C40" strokeWidth="2" />
        <path
            d="M11.0542 20.8267H16.9885V23H19.4878V20.8267H21.0212V18.7319H19.4878V10.6364H16.2158L11.0542 18.7681V20.8267ZM17.0368 18.7319H13.6863V18.6353L16.9403 13.4858H17.0368V18.7319Z"
            fill="#770C40"
        />
    </svg>
);
