import {Button, ModalContent, ModalHeader, useModal} from '@mmb-digital/ds-lilly';
import React, {ReactElement, ReactNode} from 'react';

export const TooltipMoreInfoButton = ({title, content}: {title: ReactElement; content: ReactNode}) => {
    const {Modal, show: handleShow, modalProps} = useModal(true);

    return (
        <>
            <Button ariaLabel="Více" onClick={handleShow}>
                Více
            </Button>
            <Modal {...modalProps}>
                <ModalHeader title={title} />
                <ModalContent>{content}</ModalContent>
            </Modal>
        </>
    );
};
