import {Col, IconList, IconListItem, Row, Text} from '@mmb-digital/ds-lilly';
import React from 'react';
import {themeNames} from '../../utils';

export const RpsnExtraInfoContent = () => {
    return (
        <Row>
            <Col>
                <Text weight={'bold'}>RPSN představuje celkové náklady spojené s uzavřením úvěru a započítávají se tam tyto částky:</Text>
            </Col>
            <IconList>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>
                        Poplatky související s úvěrem: zpracování úvěru, vedení úvěrového účtu a vedení běžného účtu jsou <strong>ZDARMA</strong>
                    </Text>
                </IconListItem>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>
                        První čerpání je <strong>zdarma</strong>
                    </Text>
                </IconListItem>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>Poplatek za posouzení vhodnosti zástavy 3&nbsp;000&nbsp;Kč</Text>
                </IconListItem>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>
                        Návrh na vklad a výmaz zástavního práva z katastru nemovitostí 2&nbsp;000&nbsp; Kč za&nbsp;každý návrh
                    </Text>
                </IconListItem>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>Ověření podpisu na zástavní smlouvě k nemovitým věcem 50 Kč</Text>
                </IconListItem>
                <IconListItem iconName="minus">
                    <Text theme={themeNames.mb['0']}>
                        V případě sjednání pojištění schopnosti splácet se započítává i měsíční splátka za pojištění
                    </Text>
                </IconListItem>
            </IconList>
        </Row>
    );
};
