import {Icon, IntegerInput as IntegerInputDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps} from 'react';
import {useFormikField} from '../../hook';
import {getErrorMessage} from './_helpers';
import {HTMLInputDefaults} from './TextInput';
import '../../styles/IntegerInput.scss';

type WithArrows = {withArrows?: boolean; min?: number; max?: number};

export const IntegerInput = (props: Omit<ComponentProps<typeof IntegerInputDs>, 'onChange'> & WithArrows & HTMLInputDefaults) => {
    const {withArrows, min, max, onBlur, ...restProps} = props;

    const [field, meta, helpers, context] = useFormikField<string>(props.name);

    const handleOnChange = (val: string | number) => {
        field.onChange({target: {name: props.name, value: val}});
    };

    const extractValueAsNumber = (): number => {
        if (typeof field.value === 'number') {
            return field.value;
        } else {
            return parseInt(field.value);
        }
    };

    const increment = () => {
        if (field.value === null || field.value === undefined) {
            return;
        }

        let newVal = extractValueAsNumber() + 1;

        if (min) {
            newVal = Math.max(newVal, min);
        }

        if (max) {
            newVal = Math.min(newVal, max);
        }

        handleOnChange(newVal);
    };

    const decrement = () => {
        if (field.value === null || field.value === undefined) {
            return;
        }

        let newVal = extractValueAsNumber() - 1;

        if (min) {
            newVal = Math.max(newVal, min);
        }

        if (max) {
            newVal = Math.min(newVal, max);
        }

        handleOnChange(newVal);
    };

    const handleOnBlur = () => {
        helpers.setTouched(true);

        if (onBlur) {
            onBlur();
        }
    };

    if (withArrows) {
        restProps.beforeControl = (
            <>
                {restProps.beforeControl}
                <span onClick={restProps.isDisabled ? undefined : decrement} className={'IntegerInput-arrow'}>
                    <Icon name={'arrowLeft'} />
                </span>
            </>
        );

        restProps.afterControl = (
            <>
                <span onClick={restProps.isDisabled ? undefined : increment} className={'IntegerInput-arrow'}>
                    <Icon name={'arrowRight'} />
                </span>
                {restProps.afterControl}
            </>
        );
    }

    return (
        <IntegerInputDs
            thousandSeparator={' '}
            {...field}
            {...restProps}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={getErrorMessage(context, meta)}
            theme={'IntegerInput'}
            // @ts-ignore
            inputMode={'numeric'}
        />
    );
};
