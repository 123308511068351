import React from 'react';

export const SmsIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.32919 18.6552C2.52387 18.6552 1.04199 17.2676 1.04199 15.5323V4.16454C1.04199 2.4277 2.52319 1.04163 4.32919 1.04163H20.313C22.1182 1.04163 23.6002 2.42801 23.6002 4.16454V15.5323C23.6002 17.2673 22.1175 18.6552 20.313 18.6552H11.4636L7.21377 22.77C6.36405 23.5917 4.87792 23.0301 4.87792 21.8302V18.6552H4.32919Z"
                fill="#FF9100"
            />
            <path
                opacity="0.900136"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.52441 11.0366H5.56049C5.61033 11.4603 6.05182 11.738 6.62148 11.738C7.1947 11.738 7.58634 11.4639 7.58634 11.0865C7.58634 10.7518 7.34068 10.5667 6.72117 10.4278L6.05182 10.2818C5.10476 10.0789 4.63835 9.59825 4.63835 8.84701C4.63835 7.89994 5.45723 7.27332 6.60368 7.27332C7.79997 7.27332 8.55121 7.88926 8.56901 8.81852H7.56142C7.52582 8.38416 7.14486 8.12069 6.60724 8.12069C6.07674 8.12069 5.7207 8.37347 5.7207 8.75444C5.7207 9.07131 5.96993 9.24933 6.56095 9.38462L7.18402 9.51636C8.21297 9.7371 8.66158 10.1786 8.66158 10.9619C8.66158 11.9588 7.85337 12.589 6.57876 12.589C5.3433 12.589 4.55646 12.0015 4.52441 11.0366ZM14.7605 12.5H13.8028V9.03215H13.7743L12.3786 12.4501H11.7021L10.3065 9.03215H10.278V12.5H9.32025V7.36233H10.5664L12.0261 11.0046H12.0546L13.5144 7.36233H14.7605V12.5ZM16.4588 11.0366H15.4228C15.4548 12.0015 16.2416 12.589 17.4771 12.589C18.7517 12.589 19.5599 11.9588 19.5599 10.9619C19.5599 10.1786 19.1113 9.7371 18.0824 9.51636L17.4593 9.38462C16.8683 9.24933 16.619 9.07131 16.619 8.75444C16.619 8.37347 16.9751 8.12069 17.5056 8.12069C18.0432 8.12069 18.4242 8.38416 18.4598 8.81852H19.4673C19.4495 7.88926 18.6983 7.27332 17.502 7.27332C16.3556 7.27332 15.5367 7.89994 15.5367 8.84701C15.5367 9.59825 16.0031 10.0789 16.9502 10.2818L17.6195 10.4278C18.239 10.5667 18.4847 10.7518 18.4847 11.0865C18.4847 11.4639 18.093 11.738 17.5198 11.738C16.9502 11.738 16.5087 11.4603 16.4588 11.0366Z"
                fill="white"
            />
        </svg>
    );
};
