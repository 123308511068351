import {AddressDto} from '@finanso/api-common';
import {PHONE_NUMBER_PREFIX} from '../constants';

export const formatAddressParts = ({
    numberDescriptive = '',
    numberOrientation = '',
    zipCode = '',
    street = '',
    city = '',
}: {
    street?: string;
    city?: string;
    zipCode?: string;
    numberDescriptive?: string;
    numberOrientation?: string;
}) => {
    if (!street && !numberOrientation && !numberDescriptive && !zipCode && !city) {
        return '';
    }

    if (street) {
        if (numberDescriptive && numberOrientation) {
            return `${street} ${numberDescriptive}/${numberOrientation}, ${zipCode} ${city}`;
        } else if (numberDescriptive) {
            return `${street} ${numberDescriptive}, ${zipCode} ${city}`;
        }
    } else {
        return `${city} ${numberDescriptive}, ${zipCode}`;
    }

    return '';
};

export const formatAddress = (address: Omit<AddressDto, 'type'>): string => {
    if (!address) {
        return '';
    }

    const street = address?.street;
    const city = address?.city;
    const zipCode = address?.zipCode;
    const streetNumberDescriptive = address?.streetNumberDescriptive;
    const streetNumberOrientation = address?.streetNumberOrientation;

    return formatAddressParts({
        city,
        street,
        zipCode,
        numberOrientation: streetNumberOrientation,
        numberDescriptive: streetNumberDescriptive,
    });
};

export const formatMobileNumberShowOnlyLast = (value: string) => {
    if (!value.startsWith(PHONE_NUMBER_PREFIX)) {
        value = `${PHONE_NUMBER_PREFIX}${value}`;
    }
    return `+*** *** *** ${value.substring(value.length - 3, value.length)}`;
};

export const formatMobileNumber = (value: string = '', withPlus = true): string => {
    if (!value.startsWith(PHONE_NUMBER_PREFIX)) {
        value = `${PHONE_NUMBER_PREFIX}${value}`;
    }

    const replaceString = `${withPlus ? '+' : ''}$1\u00a0$2\u00a0$3\u00a0$4`;
    return value ? `${value}`.replace(/\s/g, '').replace(/^\+(\d{3})(\d{3})(\d{3})(\d{3})/, replaceString) : '';
};

export const formatFullName = ({lastName, firstName}: {firstName?: string; lastName?: string}): string => `${firstName || ''} ${lastName || ''}`;

export const formatPersonalNumber = (value: string, separator = ' / '): string | undefined => {
    if (!value) {
        return;
    }

    const validValues = value.replace(/[\s\D\/]/g, '');

    if (!validValues.includes('/') && validValues.length >= 6) {
        return validValues.slice(0, 6).concat(separator, validValues.slice(6));
    } else {
        return validValues;
    }
};

export function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
