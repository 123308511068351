import {
    DATE_FORMAT,
    DATE_FORMAT_DAY_LETTER,
    DATE_FORMAT_MONTH_LETTER,
    DATE_FORMAT_SEPARATOR,
    DATE_FORMAT_WITH_SPACES,
    DATE_FORMAT_YEAR_LETTER,
    SUPPORTED_DATES_REGEX,
} from '../constants';
import moment, {Moment} from 'moment';

export const convertStringToMoment = (value: any = '') => {
    const valueAsString = `${value}`;

    if (value && valueAsString.match(SUPPORTED_DATES_REGEX)) {
        const [day, month, year] = valueAsString.split(DATE_FORMAT_SEPARATOR).map((datePart) => datePart.trim());
        const FORMAT_FROM_VALUE = [
            DATE_FORMAT_DAY_LETTER.repeat(day.length),
            DATE_FORMAT_MONTH_LETTER.repeat(month.length),
            DATE_FORMAT_YEAR_LETTER.repeat(4),
        ].join(DATE_FORMAT_SEPARATOR);

        const dateString = [day, month, year].join(DATE_FORMAT_SEPARATOR);

        return moment.utc(dateString, FORMAT_FROM_VALUE, true).startOf('day');
    }
    return moment('invalid date', DATE_FORMAT);
};

export const convertAnyToMoment = (value: any = ''): Moment => {
    if (moment.isMoment(value)) {
        return value;
    } else if (moment.isDate(value)) {
        return moment(value);
    } else if (value) {
        return convertStringToMoment(value);
    } else {
        return moment();
    }
};

export const formatDate = (value: Date | null | undefined, format = DATE_FORMAT_WITH_SPACES) => (value ? moment(value).format(format) : null);
