import {Col, Icon, Link, Row, Text, Tooltip} from '@mmb-digital/ds-lilly';
import React from 'react';
import {c, themeNames} from '../../utils';

import '../../styles/PpiInfoPopup.scss';

const BULLETS: {text: string; info: string}[] = [
    {
        text: 'Ztráta zaměstnání',
        info: 'Stát se to může. A když na to přijde, nová práce se lépe hledá s klidnou hlavou.',
    },
    {
        text: 'Pracovní neschopnost',
        info: 'Při nemoci/úrazu Vám bude pojištění hradit půjčku.',
    },
    {
        text: 'Ošetřování člena rodiny',
        info: 'Po dobu ošetřovačky Vám bude pojištění hradit půjčku.',
    },
    {
        text: 'Invalidita 3. stupně',
        info: 'Po přiznání invalidity Vám pojištění uhradí zbývající dluh na půjčce.',
    },
    {
        text: 'Úmrtí',
        info: 'Při úmrtí pojištění uhradí místo pozůstalých zbývající dluh na půjčce.',
    },
    {
        text: 'Hospitalizace',
        info: 'Při pobytu v nemocnici Vám pojištění uhradí měsíční splátku.',
    },
];

const PpiBullets = () => {
    return (
        <Row theme={themeNames.mb['0']}>
            {BULLETS.map((bullet, index) => {
                return (
                    <Col key={index} xs={12} md={6} theme={c(themeNames.pr['0'], themeNames.pb.medium)}>
                        <Row theme={themeNames.mb['0']}>
                            <Col xs={'auto'} theme={themeNames.pl.xxSmall}>
                                <Tooltip content={bullet.info} trigger={'hover'}>
                                    <div>
                                        <Text size={'small'}>
                                            <Icon name={'insuranceFill'} color={'var(--greenDark)'} />
                                            {bullet.text}
                                            {'  '}
                                            <Icon name={'infoFill'} backgroundColor={'greyNormal'} size={'small'} />
                                        </Text>
                                    </div>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );
};

export const PpiInfoPopup = ({onClose}: {onClose: () => void}) => {
    return (
        <div className={'PpiInfoPopup'}>
            <Row theme={c(themeNames.mb['0'])}>
                <Col xs={12}>
                    <Text size={'large'} weight={'semiBold'}>
                        Seznam situací, se kterými Vám pojištění pomůže
                    </Text>
                </Col>
                <Col xs={12} theme={themeNames.pb.medium}>
                    <Text size={'small'}>A k tomu sleva 0,2 % z roční úrokové sazby</Text>
                </Col>
                <PpiBullets />
                <Col xs={12} theme={themeNames.mb.xxSmall}>
                    <Link
                        linkUrl={'https://www.moneta.cz/pojisteni/pojisteni-schopnosti-splacet-hypotecni-uver'}
                        ariaLabel={'Covid info'}
                        color={'secondary'}
                        target={'_blank'}
                    >
                        Jak Vás pojištění chrání v období COVIDu?
                    </Link>
                </Col>
                <Col xs={12}>
                    <Link
                        linkUrl={'https://www.moneta.cz/pojisteni/pojisteni-schopnosti-splacet-hypotecni-uver'}
                        ariaLabel={'Learn more'}
                        color={'secondary'}
                        target={'_blank'}
                    >
                        Zjistit více
                    </Link>
                </Col>
            </Row>
            <div className={'PpiInfoPopup__Close'} onClick={onClose}>
                <Icon name={'close'} />
            </div>
        </div>
    );
};
