export function noop() {
    return;
}

export function isNumber(value: number | null | undefined | '') {
    return !(value === null || value === '' || value === undefined);
}

export function isNilOrEmptyString(value: any) {
    return value === undefined || value === null || value === '';
}

export function numberOrDefault(value: number | null | undefined | '', defaultValue: number): number {
    // @ts-ignore
    return isNumber(value) ? value : defaultValue;
}

export function isFunction(obj: any) {
    return obj && {}.toString.call(obj) === '[object Function]';
}

export function isEmpty(obj: any) {
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const round = (numberToShorten: number, roundTo: number = 1): number =>
    roundTo === 0 ? numberToShorten : Math.round(numberToShorten / roundTo) * roundTo;

export const flatten = (c: any, d = '.', stopRecursionIfKey: string = '') => {
    const r = {};
    (function f(o = {}, p) {
        Object.keys(o).forEach((k) =>
            o[k] && typeof o[k] === 'object' && (!stopRecursionIfKey || !Object.keys(o[k]).includes(stopRecursionIfKey))
                ? // @ts-ignore
                  f(o[k], p ? `${p}${d}${k}` : k)
                : // @ts-ignore
                  (r[p ? `${p}${d}${k}` : k] = o[k])
        );
    })(c);
    return r;
};
