import {CompanyGetResult, CompanySearchData} from '@finanso/api-common';
import {FormGroup, FormText, Icon, Label, Text, Tooltip} from '@mmb-digital/ds-lilly';
import React, {KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import AsyncSelect from 'react-select/async';

import keycode from 'keycode';
import {components, InputActionMeta} from 'react-select';
import {SelectComponentsConfig} from 'react-select/src/components';
import {ControlProps} from 'react-select/src/components/Control';
import {useMountedState} from 'react-use';
import {useEventListener, useFormikField, useToggler} from '../../hook';
import {c, formatAddress, getClickOrTouch, hasSomeParentTheClass, themeNames} from '../../utils';
import {getErrorMessage} from './_helpers';
import {SelectFieldCustomStyle, SelectFieldInvalidCustomStyle} from './_SelectField.constants';

import './IcoInputField.scss';

export interface ICompanyOption {
    value: CompanySearchData | undefined;
    label: ReactNode;
    disabled?: boolean;
}

const handleLoadingMessage = () => {
    return 'Načítám společnosti';
};

const handleNoOptionsMessage = ({inputValue}: {inputValue: string}) => {
    if (!inputValue) {
        return null;
    }

    return 'Zadejte IČ nebo název';
};

const placeholderOption: ICompanyOption = {
    disabled: true,
    value: undefined,
    label: 'Zadejte IČ nebo název',
};

let searchValueChangeTimeout: number;

type IcoInputFieldProps = {
    placeholder?: string;
    label?: ReactNode;
    name: string;
    labelTooltip?: ReactNode;
    getSuggestions: (companyPart: string) => Promise<(CompanyGetResult | CompanySearchData)[] | string>; // string type represents error when calling getSuggestions
    isDisabled?: boolean;
};

const formatNameWithIco = (name?: string, ico?: string) => {
    if (!name && !ico) {
        return 'Neplatná hodnota názvu a IČ';
    } else if (!name) {
        return `IČO ${ico}`;
    } else if (!ico) {
        return `${name}`;
    } else {
        return `${name}, IČO ${ico}`;
    }
};

const CompanyOption = ({data}: {data: CompanyGetResult | CompanySearchData}) => {
    return (
        <>
            <Text weight={'semiBold'} theme={themeNames.mb['0']}>
                {formatNameWithIco(data.name, data.ic)}
            </Text>
            <Text size={'small'} weight={'normal'} theme={themeNames.mb['0']}>
                {`${formatAddress({
                    ...data.address,
                    streetNumberDescriptive: data?.address?.descriptiveNumber,
                    streetNumberOrientation: data?.address?.orientationNumber,
                })}`}
            </Text>
        </>
    );
};

const Control = ({children, ...props}: ControlProps<any, false>) => {
    return (
        <components.Control {...props}>
            <Icon name={'search'} size={'small'} theme={themeNames.ml.xSmall} />
            {children}
        </components.Control>
    );
};

type ShowSuggestions = 'YES' | 'NO' | 'MANUAL';

const getComponents: (showSuggestions: ShowSuggestions, handleManualClick: () => void) => SelectComponentsConfig<ICompanyOption, false> = (
    showSuggestions,
    handleManualClick
) => {
    return {
        Control: Control,
        Option: (p) => {
            const [focused, setFocused, setNotFocuses] = useToggler(false);

            return (
                <components.Option {...p} isFocused={focused}>
                    <div onMouseLeave={setNotFocuses} onMouseEnter={setFocused}>
                        {p.children}
                    </div>
                </components.Option>
            );
        },
        Menu: (p) => {
            return (
                <components.Menu {...p}>
                    <>
                        {p.children}
                        <div className={`${classPrefix}__option`} onClick={handleManualClick}>
                            Zadat ručně
                        </div>
                    </>
                </components.Menu>
            );
        },
        IndicatorSeparator: null,
        LoadingIndicator: showSuggestions !== 'MANUAL' ? components.LoadingIndicator : null,
        DropdownIndicator: null,
    };
};

const classPrefix = 'ico-input-field';

export const IcoInputField = (props: IcoInputFieldProps) => {
    const {name, labelTooltip, label, isDisabled, getSuggestions, placeholder} = props;

    const componentRef = useRef<HTMLDivElement>(null);
    const mounted = useMountedState();

    const [searchValue, setSearchValue] = useState<string>('');
    const [showSuggestions, setShowSuggestions] = useState<ShowSuggestions>('NO');
    const [companies, setCompanies] = useState<ICompanyOption[]>();
    const [lastSearch, setLastSearch] = useState<string>();
    const [field, meta, handlers, context] = useFormikField<string>(name);

    useEffect(() => {
        if (meta.initialValue) {
            setSearchValueFromInit();
        }
    }, [meta.initialValue]);

    const handleSetCompanies = (comps: ICompanyOption[]) => {
        const newCompanies = [...comps];

        if (comps.length === 0) {
            newCompanies.unshift(placeholderOption);
        }

        setCompanies(newCompanies);

        return newCompanies;
    };

    const handleErrorResponse = (error: string) => {
        return handleSetCompanies([{disabled: true, value: undefined, label: error}]);
    };

    const setSearchValueFromInit = async () => {
        try {
            const response = await getSuggestions((meta.initialValue || '').trim());

            if (typeof response === 'string') {
                handleErrorResponse(response);
                return;
            }

            const data = (!!response && !!response.length && response[0]) || {};
            handleSetSearchValue(formatNameWithIco(data?.name, meta.initialValue));
            handleSetCompanies([
                {
                    value: data,
                    label: <CompanyOption data={data} />,
                },
            ]);
        } catch (e) {
            handleSetSearchValue(meta.initialValue);
        }
    };

    const handleSetSearchValue = (value: string = '') => {
        if (!mounted()) {
            return;
        }

        setSearchValue(value);
    };

    const getSuggestionsSelect = async (companyPart: string): Promise<ICompanyOption[] | undefined> => {
        if (companyPart === lastSearch) {
            return;
        }

        const newComps = await getSuggestions(companyPart.trim());

        setLastSearch(companyPart);

        if (!mounted()) {
            return;
        }

        if (typeof newComps === 'string') {
            return handleErrorResponse(newComps);
        }

        if (newComps && newComps.length) {
            return handleSetCompanies(
                newComps.map((c) => ({
                    value: c,
                    label: <CompanyOption data={c} />,
                }))
            );
        } else {
            return handleSetCompanies([]);
        }
    };

    const handleOnChange = (option: ICompanyOption) => {
        const company = option.value;

        setSearchValue(formatNameWithIco(company?.name, company?.ic));
        setShowSuggestions('NO');

        if (handlers && handlers.setValue) {
            handlers.setValue(company?.ic || '');
        }
    };

    const handleInputChange = (value: string, {action}: InputActionMeta) => {
        if (action === 'input-change') {
            if (!value) {
                reset();
            } else {
                handlers.setValue(value);
                setSearchValue(value);

                if (showSuggestions !== 'MANUAL') {
                    setShowSuggestions('YES');
                }
            }
        }
    };

    const reset = () => {
        if (!!field.onChange) {
            handlers.setValue('');
        }

        handleSetCompanies([]);
        setSearchValue('');
    };

    const debounceInput = (inner: typeof getSuggestionsSelect, ms = 0) => {
        let resolves: Array<(value?: ICompanyOption[] | PromiseLike<ICompanyOption[]>) => void> = [];
        return (...args: Parameters<typeof getSuggestionsSelect>) => {
            clearTimeout(searchValueChangeTimeout);
            searchValueChangeTimeout = window.setTimeout(() => {
                const result = inner(...args);
                // @ts-ignore
                resolves.forEach((r) => r(result));
                resolves = [];
            }, ms);

            return new Promise((r) => resolves.push(r));
        };
    };

    const handleGeneralClick = useCallback(
        (event) => {
            if (!componentRef || !componentRef.current) {
                return;
            }

            if (hasSomeParentTheClass(event.target, `${classPrefix}__option`) && componentRef.current.contains(event.target)) {
                return;
            }

            const innerClick = componentRef.current.contains(event.target);

            setShowSuggestions((current) => {
                if (current === 'MANUAL') {
                    handleSetCompanies([]);
                    return 'MANUAL';
                }

                return !innerClick ? 'NO' : current === 'NO' ? 'YES' : 'NO';
            });
        },
        [showSuggestions, setShowSuggestions]
    );
    useEventListener(getClickOrTouch(), handleGeneralClick);

    const handleOnKeyPress = (e: KeyboardEvent) => {
        const code = e.keyCode || e.which;
        if (code === keycode.codes.enter && (!companies || companies.length === 0)) {
            e.preventDefault();
            e.stopPropagation();
        } else if (code === keycode.codes.down || code === keycode.codes.up) {
            setShowSuggestions('YES');
        } else if (code === keycode.codes.tab && showSuggestions) {
            setShowSuggestions('NO');
        }
    };

    const error = getErrorMessage(context, meta);

    const handleManualClick = () => {
        setShowSuggestions('MANUAL');
        handleSetCompanies([]);
        if (!searchValue.match(/^\d+$/)) {
            setSearchValue('');
        }
    };

    const handleOnBlur = () => {
        setShowSuggestions('NO');
    };

    const handleOnFocus = () => {
        setShowSuggestions('YES');
    };

    return (
        <div ref={componentRef}>
            <FormGroup hasError={!!error} hasLabel={!!label}>
                {label && (
                    <Label theme={c('BrickInput-label')} isDisabled={isDisabled}>
                        {label}
                        {labelTooltip && (
                            <Tooltip trigger="hover" content={labelTooltip}>
                                <span>
                                    <Icon name="info" backgroundColor="secondaryNormal" isRight size={'small'} />
                                </span>
                            </Tooltip>
                        )}
                    </Label>
                )}
                <AsyncSelect<any>
                    className={classPrefix}
                    classNamePrefix={classPrefix}
                    components={getComponents(showSuggestions, handleManualClick)}
                    styles={!!error ? SelectFieldInvalidCustomStyle(classPrefix) : SelectFieldCustomStyle(classPrefix)}
                    id={name}
                    isClearable={false}
                    isDisabled={isDisabled}
                    isSearchable={true}
                    name={name}
                    closeMenuOnSelect={false}
                    disabled={isDisabled}
                    defaultOptions={companies}
                    inputValue={searchValue}
                    loadOptions={debounceInput(getSuggestionsSelect, 300)}
                    loadingMessage={handleLoadingMessage}
                    noOptionsMessage={handleNoOptionsMessage}
                    menuIsOpen={showSuggestions === 'YES'}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    onInputChange={handleInputChange}
                    options={companies}
                    placeholder={placeholder}
                    value={searchValue}
                    onKeyDown={handleOnKeyPress}
                    onFocus={handleOnFocus}
                    isOptionDisabled={(option) => {
                        return option.disabled;
                    }}
                />
                {error && <FormText hasError={true}>{error}</FormText>}
            </FormGroup>
        </div>
    );
};
