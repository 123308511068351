import {focusInput} from './events';
import {flatten} from './functions';

const DEFAULT_SCROLL_OFFSET = 150;
const AFTER_SCROLL_FOCUS_DELAY = 350;

export const scrollToFirstError = (errors: any) => {
    if (!errors) {
        return;
    }

    const flattenObject = flatten(errors, '.', '$$typeof');
    const inputName = Object.keys(flattenObject)[0];
    const inputAttr = `name='${inputName}'`;

    const el: HTMLAnchorElement | null = document.querySelector(`*[${inputAttr}]`);

    if (!el) {
        return;
    }

    scrollToElem(el, DEFAULT_SCROLL_OFFSET);
    setTimeout(() => {
        focusInput(el);
    }, AFTER_SCROLL_FOCUS_DELAY);
};

export const scrollToElem = (element: Element, offset = 0) => {
    if (element) {
        const viewportOffset = element.parentElement?.getBoundingClientRect();
        // these are relative to the viewport
        const top = (viewportOffset?.top || 0) + window.pageYOffset - offset;
        window.scrollTo({top, behavior: 'smooth'});
        return;
    } else {
        return false;
    }
};

export function isElementInViewport(el: Element, bottomOffset = 0) {
    if (!el) {
        return true;
    }

    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - bottomOffset &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
