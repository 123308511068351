import {Checkbox as CheckboxDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps, ReactNode} from 'react';
import {useFormikField} from '../../hook';
import {getErrorMessage} from './_helpers';

export const CheckboxInput = (
    props: ComponentProps<typeof CheckboxDs> & {
        label: ReactNode;
        isDisabled?: boolean;
    }
) => {
    const [field, meta, , context] = useFormikField(props.name);

    return (
        <CheckboxDs
            {...props}
            {...field}
            // @ts-ignore
            error={getErrorMessage(context, meta)}
        />
    );
};
