import {Icon, TextInput as TextInputDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps, FormEvent} from 'react';
import {useFormikField} from '../../hook';
import {getErrorMessage} from './_helpers';
import {emailValuesMask, onlyTextStrictValuesMask, onlyTextValuesMask} from '../../utils/configureMask';

export type HTMLInputDefaults = {
    autoFocus?: boolean;
    hasAsyncValidation?: boolean;
    type?: HTMLInputElement['type'];
    inputMode?: HTMLInputElement['inputMode'];
    strictInputMode?: boolean;
    isEmail?: boolean;
    allowForeignPhoneNumber?: boolean;
};

export const TextInput = (props: ComponentProps<typeof TextInputDs> & HTMLInputDefaults) => {
    const [field, meta, , context] = useFormikField<string>(props.name);

    const textProps = {
        ...field,
        ...props,
        mask: !!props.isEmail ? emailValuesMask : !!props.strictInputMode ? onlyTextStrictValuesMask : onlyTextValuesMask,
    };
    if (props.hasAsyncValidation && !props.isDisabled) {
        // DO NOT VALIDATE ON CHANGE ONLY ON BLUR
        // const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
        //     field.onChange(e);
        //
        //     validateField();
        // };

        const handleOnBlur = (e: FormEvent<HTMLInputElement>) => {
            field.onBlur(e);
            validateField();
        };

        const validateField = () => {
            // Formik sets isValidating incorrectly when asyncValidation used, hence the timeout
            setTimeout(() => {
                context.validateField(props.name);
            });
        };

        textProps.suffix = field.value && context.isValidating ? <Icon name={'loading'} /> : undefined;
        textProps.isDisabled = context.isSubmitting;
        textProps.onBlur = handleOnBlur;
    }

    return <TextInputDs {...field} {...props} {...textProps} error={getErrorMessage(context, meta)} />;
};
