import {useDebounce, useMount} from 'react-use';
import {FormikContextType, useFormikContext} from 'formik';
import {useState} from 'react';
import {noop} from '../../utils';

export type FormikOnChangeHandler<T> = (arg: {nextValues: T}, formik?: FormikContextType<T>) => any;

type OnChangeEffectProps<T> = {
    onChange: FormikOnChangeHandler<T>;
    delay: number;
    runMount?: boolean;
};

export function DebounceOnChangeFormikEffect<T>({onChange = noop, delay, runMount}: OnChangeEffectProps<T>) {
    const formik = useFormikContext<T>();
    const [previousValues, setPreviousValues] = useState<T>(formik.values);

    useMount(() => {
        if (runMount) {
            onChange({nextValues: formik.values}, formik);
        }
    });

    useDebounce(
        () => {
            // TODO why formik updates so frequently
            if (JSON.stringify(previousValues) !== JSON.stringify(formik.values)) {
                onChange({nextValues: formik.values}, formik);
                setPreviousValues(formik.values);
            }
        },
        delay,
        [formik.values, previousValues]
    );

    return null;
}
