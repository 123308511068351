import {useCallback, useState} from 'react';

export function useToggler(defaultValue: boolean = false): [boolean, () => void, () => void] {
    const [toggled, setToggled] = useState<boolean>(defaultValue);

    const setToggledOn = useCallback(() => {
        setToggled(true);
    }, []);

    const setToggledOff = useCallback(() => {
        setToggled(false);
    }, []);

    return [toggled, setToggledOn, setToggledOff];
}
