import React from 'react';

export const Year = ({value}: {value: string | number | undefined}) => {
    if (!value) {
        return null;
    }

    if (typeof value === typeof 'string') {
        value = +value;
    }

    if (value === 1) {
        return <>1 rok</>;
    } else if (value < 5) {
        return <>${value} roky</>;
    } else {
        return <>{value} let</>;
    }
};
