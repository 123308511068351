import {ClipboardEvent, KeyboardEvent, MouseEventHandler} from 'react';
import {isFunction} from './functions';

export function valueToEvent(value: any, name: string) {
    return {target: {name, value}};
}

export const preventDefault: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

export const focusInput = (inputElementToFocus: HTMLElement) => {
    if (inputElementToFocus) {
        inputElementToFocus.focus();
        return true;
    } else {
        return false;
    }
};

type EventValueFormatter = (newChar: string) => string | undefined;

export function rewriteInputValueEvent(
    event: KeyboardEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>,
    newChar: string,
    newValueFn: EventValueFormatter
) {
    try {
        const target = event.currentTarget;
        const start = target.selectionStart;

        // selection not supported - only bypass the event
        if (start === null) {
            return;
        }

        const value = newValueFn(newChar);

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;

        if (!nativeInputValueSetter) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        nativeInputValueSetter.call(target, value);

        target.selectionStart = target.selectionEnd = start + 1 + newChar.length;

        const newEvent = new Event('input', {
            bubbles: true,
            // @ts-ignore
            data: newChar,
        });
        event.target.dispatchEvent(newEvent);
    } catch (e) {
        return;
    }
}

export const formatOnPaste = (formatter: EventValueFormatter) => (pasteEvent: ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = pasteEvent.clipboardData || ((window as any).clipboardData as DataTransfer);

    if (!clipboardData) {
        return;
    }

    const pastedData = clipboardData.getData('Text');

    rewriteInputValueEvent(pasteEvent, pastedData, formatter);
};

// returns true if the element or one of its parents has the class classname
export function hasSomeParentTheClass(element: HTMLElement, classname: string): boolean {
    if (element.className && isFunction(element.className.split) && element.className.split(' ').indexOf(classname) >= 0) {
        return true;
    }
    return !!element.parentElement && hasSomeParentTheClass(element.parentElement, classname);
}
