import {Button, Col, ModalContent, ModalFooter, ModalHeader, Row, Text, useModal} from '@mmb-digital/ds-lilly';
import React, {useState, VFC} from 'react';
import {
    BonitaCheckResponse,
    ClientDto,
    CodebookDto,
    IncomeAndInstallmentData,
    MortgageCalculationRequest,
    MortgageDto,
    MortgageParamsAndBonitaCheckResponse,
    RateDto,
} from '@finanso/api-common';
import {ObjectSchema} from 'yup';
import {useApiState} from '../../context';

import {c, themeNames} from '../../utils';
import {BackdropLoader} from '../BackdropLoader/BackdropLoader';
import {DebounceOnChangeFormikEffect, Form, FormikForm, FormikOnChangeHandler} from '../form';
import {BonitaPanel} from './BonitaPanel';
import {MortgageFormSummary} from './MortgageFormSummary';
import {getInitData, MortgageFormDataProps} from './MortgageForm.schema';
import {MortgageFormFields} from './MortgageFormFields';
import {convertOffsetToMortgage} from './utils';

import '../../styles/MortgageModal.scss';

type UpdateMortgageDataFn = (mortgage: MortgageCalculationRequest) => Promise<MortgageParamsAndBonitaCheckResponse | undefined>;

type GetMortgageCalculationFn = (
    mortgage: MortgageCalculationRequest,
    incomeAndInstallment?: IncomeAndInstallmentData
) => Promise<MortgageParamsAndBonitaCheckResponse | undefined>;

export type MortgageModalProps = {
    onClose: () => any;
    rates?: RateDto[];
    updateMortgageData: UpdateMortgageDataFn;
    getMortgageCalculation: GetMortgageCalculationFn;
    mortgage?: MortgageDto;
    coApplicant?: ClientDto;
    mainApplicant?: ClientDto;
    validationSchema: ObjectSchema<MortgageFormDataProps | undefined, object>;
    fixations: CodebookDto;
};

export const MortgageModal: VFC<MortgageModalProps> = ({
    onClose,
    getMortgageCalculation,
    updateMortgageData,
    rates,
    mortgage,
    mainApplicant,
    coApplicant,
    validationSchema,
    fixations,
}) => {
    const [bonita, setBonita] = useState<BonitaCheckResponse | undefined>(undefined);

    const {Modal, modalProps, hide} = useModal(true, onClose);

    const [mortgageData, setMortgageData] = useState<{
        mortgage: MortgageDto | undefined;
        rates: RateDto[] | undefined;
    }>({mortgage, rates});

    const handleSubmit = async (values: MortgageFormDataProps) => {
        try {
            await validationSchema.validate(values);

            await updateMortgageData({
                realtyAmount: values.realtyAmount,
                term: values.term,
                insurance: values.insurance,
                cbFixation: {
                    code: values.fixation,
                },
                amountMortgage: values.amount,
            });
            hide();
        } catch (e) {
            // hups TODO
        }
    };
    const {loadingInProgress} = useApiState();

    const handleChange: FormikOnChangeHandler<MortgageFormDataProps> = async ({nextValues}, formik) => {
        try {
            await validationSchema.validate(nextValues);

            const mortgageRequest = {
                realtyAmount: nextValues.realtyAmount,
                term: nextValues.term,
                insurance: nextValues.insurance,
                cbFixation: {
                    code: nextValues.fixation,
                },
                amountMortgage: nextValues.amount,
            };

            const response = await getMortgageCalculation(mortgageRequest, {
                coApplicantIncome: (coApplicant?.income && coApplicant?.income.length > 0 && coApplicant?.income[0].netIncome) || undefined,
                coApplicantOtherInstallment: coApplicant?.installment?.total,
                hasCoApplicant: !!coApplicant,
                income: (mainApplicant?.income && mainApplicant?.income.length > 0 && mainApplicant?.income[0].netIncome) || 0,
                otherInstallment: mainApplicant?.installment?.total,
                mortgageInstallment: 0,
            });

            if (formik?.dirty) {
                setBonita(response?.bonitaCheckResponse);
            }

            const offset = (response?.mortgageParamsResponse?.offsetVariants && response?.mortgageParamsResponse?.offsetVariants[0]) || {};

            setMortgageData({
                mortgage: convertOffsetToMortgage(offset, mortgageRequest),
                rates: offset && offset.rates ? offset.rates : [],
            });
        } catch (e) {
            if (formik) {
                formik.submitForm();
            }
        }
    };

    return (
        <Modal {...modalProps} isVisible={true} theme={'MortgageModal'}>
            <FormikForm<MortgageFormDataProps> initialValues={getInitData(mortgage)} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <DebounceOnChangeFormikEffect<MortgageFormDataProps> onChange={handleChange} delay={500} runMount={true} />
                    <ModalHeader
                        title={
                            <Text isTextCenter={true} weight={'bold'}>
                                Vaše nová hypotéka
                            </Text>
                        }
                        theme={'MortgageModal-header'}
                    />
                    <ModalContent theme={'MortgageModal-content'}>
                        <BonitaPanel bonita={bonita} theme={themeNames.mt.large} />
                        <BackdropLoader isLoading={!!loadingInProgress} />
                        <Row theme={c(themeNames.justify.center, themeNames.align.center, themeNames.mb['0'])}>
                            <Col xs={12} md={6} lg={5} theme={c('MortgageModal-form', themeNames.h['100'])}>
                                <div className={c(themeNames.pt.large, themeNames.pb.small)}>
                                    <MortgageFormFields rates={mortgageData.rates} fixations={fixations} />
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={5} theme={c('MortgageModal-summary', themeNames.h['100'])}>
                                {mortgageData?.mortgage && <MortgageFormSummary mortgage={mortgageData.mortgage} />}
                            </Col>
                        </Row>
                    </ModalContent>
                    <ModalFooter theme={'MortgageModal-footer'}>
                        <Row theme={c(themeNames.justify.center, themeNames.align.center, themeNames.pt.small, themeNames.h['100'])}>
                            <Col xs={12} md={4}>
                                <Button
                                    elementType="submit"
                                    isBlock={true}
                                    ariaLabel={'Pokračovat'}
                                    color={'green'}
                                    theme={'MortgageModal-footerBtn'}
                                >
                                    Pokračovat
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Form>
            </FormikForm>
        </Modal>
    );
};

