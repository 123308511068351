import {Select as SelectDs, SelectPropsType} from '@mmb-digital/ds-lilly';
import React from 'react';
import {useFormikField} from '../../hook';
import {getErrorMessage} from './_helpers';

export const SelectField = (props: SelectPropsType) => {
    const [field, meta, , context] = useFormikField(props.name);

    const handleOnChange = (value: any) => {
        field.onChange({target: {value, name: props.name}});
    };

    const handleOnBlur = () => {
        field.onBlur({target: {name: props.name}});
    };

    return (
        <SelectDs
            {...field}
            {...props}
            error={getErrorMessage(context, meta)}
            // @ts-ignore - incorrect props in select ds type
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
    );
};
