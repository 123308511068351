export const phoneNumberMask = (value: string) => {
    if (!value) {
        return [];
    }
    const onlyCharsValue = value.replace(/[_\s]/g, '');

    return [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/].slice(0, onlyCharsValue.length + Math.floor(onlyCharsValue.length / 3));
};
export const phoneNumberMaskForeign = (value: string) => {
    if (!value) {
        return [];
    }

    return Array(value.length).fill(/\d/).slice(0, 18);
};
export const getPhoneNumberMask = (allowForeignPhoneNumber?: boolean) => {
    if (allowForeignPhoneNumber) {
        return phoneNumberMaskForeign;
    } else return phoneNumberMask;
};

export const onlyTextValuesMask = (value: string) => {
    if (!value) {
        return [];
    }
    value = value.replace(/_/g, '');
    return Array(value.length).fill(/[^<>{}[&\]"|;#$'`\\+?*%°=]/);
};

export const onlyTextStrictValuesMask = (value: string) => {
    if (!value) {
        return [];
    }
    value = value.replace(/_/g, '');
    return Array(value.length).fill(/[^´:/.,¨()!_§<>{}[&\]"|;#$'`\\+?*%°=]/);
};
export const emailValuesMask = (value: string) => {
    if (!value) {
        return [];
    }
    return Array(value.length).fill(/[^´,¨()§<>[\];`\\°]/);
};
export const personalNumberMask = (value: string) => {
    if (!value) {
        return [];
    }

    if (value.includes('/') && value.length <= 6) {
        value = value.replace('/', '');
    }

    if (value.length < 6) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/].slice(0, value.length);
    } else if (value.length === 6) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/'];
    } else {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/].slice(0, value.length);
    }
};
