import {Loader} from '@mmb-digital/ds-lilly';
import React from 'react';
import {c} from '../../utils';

import './BackdropLoader.scss';

export const BackdropLoader = ({isFullScreen, isLoading}: {isLoading: boolean | number; isFullScreen?: boolean}) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className={c('BackdropLoader', isFullScreen ? 'BackdropLoader--fullscreen' : '')}>
            <Loader theme={'BackdropLoader-Loader'} />
        </div>
    );
};
