import {storageFactory} from '@finanso/common';

export type StorageKeys =
    | 'applicationUid'
    | 'clientMobileNumber'
    | 'expires_in'
    | 'token_type'
    | 'refresh_token'
    | 'refresh_token_expires_at'
    | 'clientUid'
    | 'access_token';

export const storageService = storageFactory<StorageKeys>('landing-page');
