export interface Action<T extends string> {
    type: T;
}

export interface PayloadAction<T extends string, P> extends Action<T> {
    payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
// eslint-disable-next-line
export function createAction<T extends string, P>(type: T, payload: P): PayloadAction<T, P>;
// eslint-disable-next-line
export function createAction<T extends string, P>(type: T, payload?: P) {
    return payload === undefined ? {type} : {type, payload};
}

type FunctionType = (...args: any[]) => unknown;

interface ActionCreatorsMapObject {
    [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;
