import {TextInput as TextInputDs} from '@mmb-digital/ds-lilly';
import React, {ComponentProps} from 'react';
import {useFormikField} from '../../hook';
import {getErrorMessage} from './_helpers';
import {TextInput} from './TextInput';
import './SmsCodeInput.scss';

export const smsCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const SmsCodeInput = (props: ComponentProps<typeof TextInput>) => {
    const [field, meta, , context] = useFormikField<number>(props.name);

    const textProps = {
        ...field,
        ...props,
        mask: smsCodeMask,
        error: getErrorMessage(context, meta),
        theme: 'SmsCodeInput',
        inputMode: 'numeric',
    };

    return <TextInputDs {...textProps} />;
};
