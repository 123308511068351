export function removeLeadTrailSlash(val: string) {
    if (val.endsWith('/')) {
        val = val.slice(0, -1);
    }

    if (val.startsWith('/')) {
        val = val.slice(1);
    }

    return val;
}
