import {ConfigDto, UserTypeEnum} from '@finanso/api-common';
import React, {ReactNode, useState} from 'react';
import {useMount} from 'react-use';
import {BackdropLoader} from '../components';
import {apiWrapper} from '../utils';
import {useApiDispatch} from './ApiContext';

const configContextInitState = {
    role: (undefined as unknown) as UserTypeEnum,
    updateRole: (undefined as unknown) as () => Promise<void>,
    systemsDown: false,
    systemErrorMessage: (undefined as unknown) as string | undefined,
    isNonProd: false,
    shouldLog: false,
};

const ConfigContext = React.createContext<ConfigContextState>(configContextInitState);
type ConfigContextState = typeof configContextInitState;

interface ConfigProviderProps {
    children: ReactNode;
    getInfo: () => Promise<ConfigDto>;
}

function ConfigProvider({children, getInfo}: ConfigProviderProps) {
    const [providerState, setProviderState] = useState<ConfigContextState>(configContextInitState);
    const dispatch = useApiDispatch();

    const updateRole = async () => {
        return loadData();
    };

    const loadData = async () => {
        try {
            const response = await apiWrapper({
                dispatch,
                asyncFn: async () => getInfo(),
            });

            setProviderState({
                role: response?.userType || UserTypeEnum.Anonym,
                updateRole,
                systemsDown: response?.systemsDown || false,
                systemErrorMessage: response?.systemErrorMessage,
                isNonProd: response?.env === 'badminton',
                shouldLog: response?.env === 'badminton' || response?.userType === UserTypeEnum.Bo,
            });
        } catch (e) {
            setProviderState({
                role: UserTypeEnum.Anonym,
                updateRole,
                systemsDown: true,
                systemErrorMessage: undefined,
                isNonProd: false,
                shouldLog: false,
            });
        }
    };

    useMount(() => {
        loadData();
    });

    return (
        <ConfigContext.Provider value={providerState}>
            {providerState.role ? children : <BackdropLoader isLoading={true} isFullScreen={true} />}
        </ConfigContext.Provider>
    );
}

function useConfigContext() {
    const context = React.useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('useConfigContext must be used within a ConfigProvider');
    }
    return context;
}

export {ConfigProvider, ConfigContext, useConfigContext};
