import {StylesConfig} from 'react-select/src/styles';

export const SelectFieldCustomStyle: (prefixClass: string) => StylesConfig<any, false> = (prefixClass) => ({
    control: (base, state) => {
        return {
            ...base,
            minHeight: 'var(--heightFControl)',
            height: 'var(--heightFControl)',
            backgroundColor: state.isDisabled ? 'var(--backgroundFControlDisabled)' : 'var(--backgroundFControl)',

            border: 'var(--borderWidthFControl) solid var(--borderColorFControl)',
            borderColor: state.isDisabled ? 'var(--backgroundFControlDisabled)' : 'var(--borderColorFControl)',
            outline: '0 !important',
            boxShadow: state.isFocused ? 'none' : base.boxShadow,
            '&:focus': {
                outline: 0,
                boxShadow: 'none',
            },
            '&:active': {
                outline: 0,
                boxShadow: 'none',
            },
            '&:hover': {
                backgroundColor: 'var(--backgroundFControlFocus)',
                borderColor: 'var(--borderColorFControlFocus)',
                outline: 0,
                boxShadow: 'none',
            },
            [`&.${prefixClass}__control--is-focused`]: {
                outline: '0 !important',
                boxShadow: 'none',
                backgroundColor: 'var(--backgroundFControlFocus)',
                borderColor: 'var(--borderColorFControlFocus)',
            },
            [`&.${prefixClass}__control--is-disabled input`]: {
                fontWeight: '500 !important',
                color: state.isDisabled ? 'var(--colorFLabelDisabled) !important' : 'var(--primaryDarker) !important',
            },
        };
    },
    indicatorsContainer(base) {
        return {
            ...base,
            paddingLeft: 'var(--paddingFControlY)',
            paddingRight: 'var(--paddingFControlY)',
        };
    },
    input: (base) => {
        return {
            ...base,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0,
            '& input': {
                fontSize: 'var(--fontSizeMedium)',
                fontWeight: '500 !important',
                color: 'var(--colorFControl) !important',
            },
        };
    },
    option(base, props) {
        const isFocus = props.isFocused && !props.isDisabled;
        const isFocusDisabled = props.isFocused && props.isDisabled;

        const colorStyle = {
            backgroundColor: isFocus ? 'var(--primaryLight)' : isFocusDisabled ? 'var(--colorFLabelDisabled)' : base.backgroundColor,
            color: isFocus || isFocusDisabled ? 'var(--whiteNormal)' : base.color,
        };

        return {
            ...base,
            ...colorStyle,
            cursor: props.isDisabled ? 'not-allowed' : 'pointer',
            '&:active': {
                ...colorStyle,
            },
        };
    },
    menu(base) {
        return {...base, zIndex: 1450};
    },
});

export const SelectFieldInvalidCustomStyle: (prefixClass: string) => StylesConfig<any, false> = (prefixClass) => {
    const defaultStyles = SelectFieldCustomStyle(prefixClass);

    return {
        ...defaultStyles,
        control: (base, props) => {
            return {
                ...(defaultStyles.control && defaultStyles.control(base, props)),
                borderColor: 'var(--primaryLight)',
                background: 'inherit',
                color: 'var(--colorFControlInvalid)',
                '&:hover': {
                    borderColor: 'var(--form-feedback-invalid-color)',
                    background: 'var(--backgroundFControlInvalid)',
                    color: 'var(--colorFControlInvalid)',
                },
            };
        },
        input: (base, props) => {
            return {
                ...(defaultStyles.input && defaultStyles.input(base, props)),
                '& input': {
                    fontWeight: '500 !important',
                    color: 'var(--colorFControlInvalid) !important',
                },
            };
        },
    };
};
