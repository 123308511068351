import {CodebookDto} from '@finanso/api-common';
import React from 'react';
import {MortgageModal, MortgageModalProps} from '../components/MortgageCalculator/MortgageModal';
import {HIDE_MORTGAGE_MODAL, hideModal, MortgageModalContextActions, SHOW_MORTGAGE_MODAL} from './MortgageModalContext.actions';

export type MortgageModalState = {
    fixations?: CodebookDto;
    opened: boolean;
};

const initialState: MortgageModalState = {
    opened: false,
};

interface MortgageModalProviderProps {
    children: React.ReactNode;
    modalProps: Omit<MortgageModalProps, 'onClose'>;
}

const MortgageModalContext = React.createContext<MortgageModalState | undefined>(undefined);
const MortgageModalDispatchContext = React.createContext<((action: MortgageModalContextActions) => void) | undefined>(undefined);

function mortgageModalReducer(state: MortgageModalState, action: MortgageModalContextActions): MortgageModalState {
    switch (action.type) {
        case SHOW_MORTGAGE_MODAL:
            return {...state, opened: true};
        case HIDE_MORTGAGE_MODAL:
            return {...state, opened: false};
        default: {
            return state;
        }
    }
}

function MortgageModalProvider({children, modalProps}: MortgageModalProviderProps) {
    const [state, dispatch] = React.useReducer(mortgageModalReducer, initialState);

    const handleOnClose = () => {
        dispatch(hideModal());
    };

    return (
        <MortgageModalContext.Provider value={state}>
            <MortgageModalDispatchContext.Provider value={dispatch}>
                {children}
                {state.opened && <MortgageModal {...modalProps} onClose={handleOnClose} />}
            </MortgageModalDispatchContext.Provider>
        </MortgageModalContext.Provider>
    );
}

function useMortgageModalState() {
    const context = React.useContext(MortgageModalContext);
    if (context === undefined) {
        throw new Error('useMortgageModalState must be used within a MortgageModalProvider');
    }
    return context;
}

function useMortgageModalDispatch() {
    const context = React.useContext(MortgageModalDispatchContext);
    if (context === undefined) {
        throw new Error('useMortgageModalDispatch must be used within a MortgageModalProvider');
    }
    return context;
}

export type MortgageModalDispatchType = ReturnType<typeof useMortgageModalDispatch>;

export {MortgageModalProvider, useMortgageModalState, useMortgageModalDispatch};
