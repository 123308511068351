import {ActionsUnion, createAction} from '../utils';

export const SHOW_MORTGAGE_MODAL = 'SHOW_MORTGAGE_MODAL';
export const HIDE_MORTGAGE_MODAL = 'HIDE_MORTGAGE_MODAL';

export const showModal = () => createAction(SHOW_MORTGAGE_MODAL);
export const hideModal = () => createAction(HIDE_MORTGAGE_MODAL);

export const MortgageModalContextActions = {
    showModal,
    hideModal,
};

// eslint-disable-next-line
export type MortgageModalContextActions = ActionsUnion<typeof MortgageModalContextActions>;
