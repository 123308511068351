import {ErrorMessageDto} from '@finanso/api-common';
import {ActionsUnion, createAction} from '../utils';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const startLoading = () => createAction(START_LOADING);
export const endLoading = () => createAction(END_LOADING);
export const addError = (payload: ErrorMessageDto) => createAction(ADD_ERROR, payload);
export const clearErrors = () => createAction(CLEAR_ERRORS);

export const ApiContextActions = {
    startLoading,
    endLoading,
    addError,
    clearErrors,
};

// eslint-disable-next-line
export type ApiContextActions = ActionsUnion<typeof ApiContextActions>;
