import {Text} from '@mmb-digital/ds-lilly';
import React from 'react';

export const Footer = () => {
    return (
        <footer>
            <Text type={'secondary'} size={'small'} isTextCenter>
                &copy; {new Date().getFullYear().toString()} MONETA Money Bank, a. s. <br />
                BB Centrum, Vyskočilova 1442/1b, 140 28 Praha 4 - Michle, IČO: 25672720
                <br />
                zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl B, vložka 5403
            </Text>
        </footer>
    );
};
