import React, {ReactNode} from 'react';
import {Button, ButtonPropsType, Col, FormGroup, FormText, Icon, Label, Row, Tooltip} from '@mmb-digital/ds-lilly';
import {useFormikField} from '../../hook';
import {c, themeNames} from '../../utils';
import {InfoTooltip} from '../InfoTooltip/InfoTooltip';
import {getErrorMessage} from './_helpers';
import isEqual from 'lodash/isEqual';
import '../../styles/BrickInput.scss';
import {HiddenInput} from './HiddenInput';

export type BrickInputChangeEvent = {
    target: {name: string; value: any};
};

export type BrickBtnType = {
    label: ReactNode;
    value: any;
    brickTooltip?: ReactNode;
    isDisabled?: boolean;
};

type BrickBtnProps = {
    label: ReactNode;
    onClick: ButtonPropsType['onClick'];
    checked: boolean;
    error: boolean;
    theme?: string;
    brickSelectedType?: ButtonPropsType['type'];
    brickNonSelectedType?: ButtonPropsType['type'];
    isBrickSmall?: boolean;
    isDisabled?: boolean;
    isWidthAuto?: boolean;
};

export type BrickInputProps = {
    label?: ReactNode;
    name: string;
    labelTooltip?: ReactNode;
    isTooltipLong?: boolean;
    bricks: BrickBtnType[];
    afterControl?: ReactNode;
    brickSelectedType?: ButtonPropsType['type'];
    brickNonSelectedType?: ButtonPropsType['type'];
    isBrickSmall?: boolean;
    isDisabled?: boolean;
    isWidthAuto?: boolean;
    isInline?: boolean;
};

export const BrickBtn = ({
    label,
    onClick,
    checked,
    error,
    brickNonSelectedType,
    brickSelectedType,
    theme,
    isBrickSmall,
    isDisabled,
    isWidthAuto,
}: BrickBtnProps) => {
    return (
        <Button
            ariaLabel={'BrickInput'}
            theme={c(
                'BrickInput__btn',
                isWidthAuto ? 'BrickInput__btn--width-auto' : '',
                error || checked ? '' : brickNonSelectedType ? '' : 'BrickInput__btn--notSelected',
                theme || ''
            )}
            onClick={onClick}
            color={error ? 'red' : checked ? 'secondary' : undefined}
            type={checked ? brickSelectedType : error ? 'border' : brickNonSelectedType}
            isSmall={isBrickSmall}
            isDisabled={isDisabled}
        >
            {label}
        </Button>
    );
};

export const onClickFactory = (value: any, name: string, fn: (e: BrickInputChangeEvent) => void) => () => {
    fn({target: {name, value}});
};

export const BrickInput = (props: BrickInputProps) => {
    const {
        label,
        name,
        bricks,
        labelTooltip,
        afterControl,
        brickNonSelectedType,
        brickSelectedType,
        isBrickSmall,
        isDisabled,
        isWidthAuto,
        isInline,
        isTooltipLong,
    } = props;
    const [field, meta, , context] = useFormikField(name);

    const handleChange = (e: BrickInputChangeEvent) => {
        field.onChange(e);
    };

    const error = getErrorMessage(context, meta);
    const hasAnyTooltip = bricks.some(({brickTooltip}) => !!brickTooltip);
    return (
        <FormGroup hasError={!!error} hasLabel>
            <Row theme={c(themeNames.mb['0'], 'BrickInput__row', themeNames.align.center, isInline ? themeNames.justify.between : '')}>
                <HiddenInput name={field.name} />
                {label && (
                    <Col xs={isInline ? undefined : 'auto'} theme={'BrickInput__label-wrapper'}>
                        <Label theme={c('BrickInput__label', isInline ? 'BrickInput__label--inline' : '')} isDisabled={isDisabled}>
                            {label}
                            {<InfoTooltip isLong={isTooltipLong}>{labelTooltip}</InfoTooltip>}
                        </Label>
                    </Col>
                )}
                <Col xs={isInline ? 'auto' : 12}>
                    <Row>
                        {bricks.map(({brickTooltip, label, value, isDisabled: isDisabledJustThisOne}) => {
                            return (
                                <Col xs={'auto'} theme={themeNames.pr.xxxSmall} key={JSON.stringify(value)}>
                                    <BrickBtn
                                        label={label}
                                        onClick={onClickFactory(value, name, handleChange)}
                                        checked={isEqual(field.value, value)}
                                        error={!!error}
                                        brickNonSelectedType={brickNonSelectedType}
                                        brickSelectedType={brickSelectedType}
                                        isBrickSmall={isBrickSmall}
                                        isDisabled={isDisabled || isDisabledJustThisOne}
                                        isWidthAuto={isWidthAuto}
                                    />
                                    {brickTooltip ? (
                                        <Tooltip content={brickTooltip}>
                                            <div
                                                className={themeNames.pl.xxSmall}
                                                style={{
                                                    display: 'inline-block',
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <Icon name={'info'} backgroundColor={'info'} size={'small'} />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        hasAnyTooltip && (
                                            <div
                                                className={themeNames.pl.xxSmall}
                                                style={{
                                                    display: 'inline-block',
                                                    verticalAlign: 'top',
                                                    width: 'calc( var(--widthSmallIcon) + 1px)',
                                                }}
                                            />
                                        )
                                    )}
                                </Col>
                            );
                        })}
                        {afterControl && <Col theme={c(themeNames.align.self.center, 'BrickInput__after-control')}>{afterControl}</Col>}
                    </Row>
                </Col>
            </Row>

            {error && <FormText hasError={true}>{error}</FormText>}
        </FormGroup>
    );
};
