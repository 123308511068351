import {Collapse, CollapsePropsType} from '@mmb-digital/ds-lilly';
import React, {FC, useState} from 'react';
import './CollapseDiv.scss';

export const CollapseDiv: FC<Omit<CollapsePropsType, 'isOpened'> & {isOpened?: boolean}> = ({isOpened, children, ...props}) => {
    const [opened, setOpened] = useState(isOpened);

    const toggleOpened = () => setOpened((prev) => !prev);

    return (
        <div className={'CollapseDiv'}>
            <Collapse isOpened={opened} onClick={toggleOpened} {...props}>
                {children}
            </Collapse>
        </div>
    );
};
