import React, {FC, KeyboardEventHandler, useEffect} from 'react';
import {
    Form as FormFormik,
    Formik,
    FormikConfig,
    FormikFormProps,
    FormikHelpers,
    FormikValues,
    useFormikContext,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import {isMobile} from 'react-device-detect';
import {Prompt} from 'react-router-dom';
import {scrollToFirstError} from '../../utils/scrolling';

const LEAVE_MESSAGE =
    'Na stránce máte neuložená data, pokud je chcete uložit, použijte prosím tlačítko Pokračovat. Přejete si pokračovat bez uložení dat?';

export const PreventLeave = ({dirty}: {dirty: boolean}) => {
    useEffect(() => {
        if (dirty) {
            window.onbeforeunload = () => LEAVE_MESSAGE;
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [dirty]);

    return <Prompt message={LEAVE_MESSAGE} when={dirty} />;
};
export type FormikFormPropsTest = FormikFormProps & {
    disableLeavePrompt?: boolean;
};
export const Form = ({children, disableLeavePrompt, ...restProps}: FormikFormPropsTest) => {
    const {submitCount, isSubmitting, isValid, dirty, errors} = useFormikContext();
    useEffect(() => {
        if (!isSubmitting && submitCount && !isValid) {
            scrollToFirstError(errors);
        }
    }, [isSubmitting]);

    const preventEnter: KeyboardEventHandler = (e) => {
        if (isMobile) {
            const code = e.keyCode || e.which;
            if (code === 13) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };

    return (
        <FormFormik translate="yes" {...restProps} onKeyDown={preventEnter} onKeyPress={preventEnter} onKeyUp={preventEnter} noValidate={true}>
            {children}

            <PreventLeave dirty={disableLeavePrompt ? false : dirty} />
        </FormFormik>
    );
};

export function FormikForm<Values extends FormikValues = FormikValues>(props: InnerIFormikFCProps<Values>) {
    const handleSubmit: FormikConfig<Values>['onSubmit'] = async (values, handlers) => {
        try {
            const result = await props.onSubmit(values, handlers);

            // result is either true or is callback function -> submit is successful
            if (result) {
                handlers.resetForm({values}); // sets formik dirty to false
            }

            // call callback function
            if (typeof result !== 'boolean' && result) {
                result();
            }
        } catch (e) {
            // automatically sets submitting flag to false
        }
    };

    return (
        <Formik<Values>
            validateOnChange={!props.validateOnBlur}
            validateOnBlur={props.validateOnBlur}
            {...props}
            validationSchema={undefined}
            validate={async (values) => {
                if (props.validate) {
                    return props.validate(values);
                }

                /*
                This method puts all data to validation.
                You can use that as follows (data are in this.option.context). You have to use function not a narrow function:

                test: function test(value) {
                  if (value?.code === CbMartialStatusEnum.married) {
                    return (this?.options?.context as FullApplicationFormData)?.auxiliary?.hasCoApplicant;
                  }
                  return true;
                },

                */

                try {
                    await validateYupSchema(values, props.validationSchema, false, values);
                } catch (err) {
                    return yupToFormErrors(err); //for rendering validation errors
                }

                return {};
            }}
            onSubmit={handleSubmit}
        />
    );
}

// False -> submit not successful -> form dirty
// Otherwise -> submit successful -> form pristine (alternatively with calling callback fn)
type SubmitReturnType = (() => any) | true | false | undefined | void;

interface InnerIFormikFCProps<T> extends Omit<FormikConfig<T>, 'onSubmit'> {
    onSubmit: (data: T, formikHelpers: FormikHelpers<T>) => Promise<SubmitReturnType>;
}

interface OuterIFormikFCProps<T> extends Omit<FormikConfig<T>, 'onSubmit' | 'initialValues'> {
    onSubmit: (data: T, formikHelpers: FormikHelpers<T>) => Promise<SubmitReturnType>;
    initialValues?: T;
}

export type FormikFCSubmitFn<T> = (data: T, formikHelpers: FormikHelpers<T>) => Promise<SubmitReturnType>;

export type FormikFC<Values, OtherProps = {}> = FC<OuterIFormikFCProps<Values> & OtherProps>;

export type FormikFCProps<Values, OtherProps = {}> = OuterIFormikFCProps<Values> & OtherProps;
