import {Alert, Link, Text} from '@mmb-digital/ds-lilly';
import React from 'react';
import {BonitaCheckResponse} from '@finanso/api-common';
import {showModal, useMortgageModalDispatch, useMortgageModalState} from '../../context';
import {themeNames} from '../../utils';
import {Currency} from '../Currency';

export const BonitaPanel = ({bonita, theme}: {bonita: BonitaCheckResponse | undefined; theme?: string}) => {
    const {opened} = useMortgageModalState();
    const dispatch = useMortgageModalDispatch();

    if (!bonita || !bonita.flags || bonita.flags.length === 0) {
        return null;
    }

    const maxActualAmount = <Currency value={Math.round(bonita.maxActualAmount || 0)} />;
    const maxTerm = Math.round((bonita.maxTerm || 0) / 12);
    const maxTermYear = bonita.maxTerm && (bonita.maxTerm === 0 || bonita.maxTerm) > 1 ? 'let' : 'rok';

    const maxAmount = <Currency value={Math.round(bonita.maxAmount || 0)} />;
    const maxInstallment = <Currency value={Math.round(bonita.maxInstallment || 0)} />;

    const handleOpenMortgageModal = () => {
        if (!opened) {
            dispatch(showModal());
        }
    };

    return (
        <Alert type={'warning'} iconName={'info'} iconBackgroundColor={'warning'} title={<span>Hypotéka je příliš vysoká?</span>} theme={theme}>
            <Text type={'orange'} theme={themeNames.mb['0']}>
                Podle Vašich příjmů a výdajů Vám můžeme půjčit maximálně {maxActualAmount}. Pro maximální splatnost {maxTerm} {maxTermYear} Vám však
                můžeme půjčit až {maxAmount} s měsíční splátkou {maxInstallment}. Překontrolujte správnost příjmů a výdajů nebo upravte{' '}
                {opened ? (
                    'parametry nové hypotéky'
                ) : (
                    <Link ariaLabel={'parametry nové hypotéky'} onClick={handleOpenMortgageModal}>
                        parametry nové hypotéky
                    </Link>
                )}
                .
            </Text>
        </Alert>
    );
};
