export function storageFactory<T extends string>(valuePrefix: string) {
    return new StorageService<T>(valuePrefix);
}

export class StorageService<T extends string = string> {
    private readonly prefix: string;

    constructor(valuePrefix: string) {
        this.prefix = valuePrefix;
    }

    public setItem(code: T, value: any) {
        return sessionStorage.setItem(`${this.prefix}.${code}`, JSON.stringify(value));
    }

    public removeItem(code: T) {
        sessionStorage.removeItem(`${this.prefix}.${code}`);
    }

    public clear() {
        Object.keys(sessionStorage).forEach((key) => {
            if (key.startsWith(this.prefix)) {
                sessionStorage.removeItem(key);
            }
        });
    }

    public getItem(code: T) {
        return sessionStorage.getItem(`${this.prefix}.${code}`) ? JSON.parse(sessionStorage.getItem(`${this.prefix}.${code}`) || '') : null;
    }
}

export const InternalDistributionCookieName = {
    INTERNAL_DISTRIBUTION: 'INTERNAL_DISTRIBUTION',
    INTERNAL_DISTRIBUTION_DATA: 'INTERNAL_DISTRIBUTION_DATA',
} as const;
const allInternalDistributionCookieNamesArray = [
    InternalDistributionCookieName.INTERNAL_DISTRIBUTION_DATA,
    InternalDistributionCookieName.INTERNAL_DISTRIBUTION,
];
export type internalDistributionCookieNames = keyof typeof InternalDistributionCookieName;

const pastDateUtc = 'Thu, 01 Jan 1970 00:00:01 GMT';
export const setInternalDistributionCookie = (cookieName: internalDistributionCookieNames, value: any) => {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    const expires = '; expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + encodeURIComponent(JSON.stringify(value)) + expires + '; secure; path=/';
};

export const deleteInternalDistributionCookies = (cookieName?: internalDistributionCookieNames) => {
    if (cookieName) {
        const cookieExists = getInternalDistributionCookie(cookieName);
        if (cookieExists) {
            document.cookie = cookieName + '=' + JSON.stringify(0) + '; expires=' + pastDateUtc + '; secure; path=/';
        }
    } else {
        allInternalDistributionCookieNamesArray.map((cookieName) => deleteInternalDistributionCookies(cookieName));
    }
};

export const getInternalDistributionCookie = (cookieName: internalDistributionCookieNames) => {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let c of ca) {
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
};
export const parseInternalDistributionDataFromCookie = () => {
    try {
        return JSON.parse(decodeURIComponent(getInternalDistributionCookie('INTERNAL_DISTRIBUTION_DATA')));
    } catch (e) {
        return undefined;
    }
};

export const parseInternalDistributionFromCookie = () => {
    try {
        return JSON.parse(decodeURIComponent(getInternalDistributionCookie('INTERNAL_DISTRIBUTION')));
    } catch (e) {
        return undefined;
    }
};
