import {ConfigurationParameters, ErrorMessageDto, ErrorMessageDtoErrorCodeEnum, Middleware} from '@finanso/api-common';
import {DefaultApi, FetchAPI, NullUsingPOSTGrantTypeEnum} from '@finanso/api-security';
import {StorageService} from './storage';
import * as Sentry from '@sentry/react';

export const defaultError: ErrorMessageDto = {
    errorCode: ErrorMessageDtoErrorCodeEnum.Unknown,
    message: 'Nastala neočekávaná chyba',
};

const F5_REJECTED_RESPONSE_REGEX = /The requested URL was rejected. Please consult with your administrator.<br><br>Your support ID is: (\d+)/;

export const isErrorStatus = (status: number) => status >= 400;

const setHeader = (init: RequestInit, name: string, value: string) => {
    if (!init.headers) {
        init.headers = {};
    }

    if (init.headers instanceof Headers) {
        init.headers.set(name, value);
    } else {
        init.headers = {
            ...init.headers,
            [name]: value,
        };
    }
};

export const getOnlyLastCallMiddleware: () => {
    context: Middleware['pre'];
    getController: () => AbortController;
} = () => {
    let controller = new AbortController();

    return {
        context: (context) => {
            if (controller) {
                controller.abort();
                controller = new AbortController();
                context.init.signal = controller.signal;
            }

            return Promise.resolve();
        },
        getController: () => controller,
    };
};

export const getDefaultPostMiddleware: (storageService: StorageService, oauthClient: DefaultApi) => Middleware['post'] = (
    storageService,
    oauthClient
) => async (context) => {
    // access token invalid and refresh present
    if (context.response.status === 401 && storageService.getItem('refresh_token')) {
        const newToken = await oauthClient.nullUsingPOST({
            grantType: NullUsingPOSTGrantTypeEnum.RefreshToken,
            refreshToken: storageService.getItem('refresh_token'),
        });

        storageService.setItem('access_token', newToken.accessToken);
        storageService.setItem('refresh_token', newToken.refreshToken);

        // set new authorization header
        setHeader(context.init, 'Authorization', `Bearer ${newToken.accessToken}`);

        // Repeat the API call
        return await context.fetch(context.url, context.init);
    }

    if (isErrorStatus(context.response.status)) {
        let error: ErrorMessageDto;

        try {
            error = await context.response.json();

            if (!error.errorCode) {
                error = defaultError;
            }
        } catch (e) {
            error = defaultError;
        }
        return Promise.reject(error);
    }
};

const checkF5RejectResponse: Middleware['post'] = async (context) => {
    try {
        const responseText = await context.response.clone().text();

        const testedResponse = F5_REJECTED_RESPONSE_REGEX.exec(responseText);

        if (testedResponse && testedResponse[1]) {
            logF5Reject(testedResponse[1]);
        }
    } catch (e) {
        // just bypass
    }

    return context.response;
};

export const getBaseApiConfigObject = ({
    storageService,
    basePath,
    oauthClient,
    fetchApi,
}: {
    fetchApi?: FetchAPI;
    basePath?: string;
    storageService: StorageService;
    oauthClient: DefaultApi;
}): ConfigurationParameters => ({
    fetchApi: fetchApi,
    basePath,
    // @ts-ignore
    apiKey: () => {
        const token = storageService.getItem('access_token');

        return token ? `Bearer ${token}` : undefined;
    },
    middleware: [
        {post: checkF5RejectResponse},
        {
            post: getDefaultPostMiddleware(storageService, oauthClient),
        },
    ],
});

export const fetchWithProgress: (onProgress?: XMLHttpRequestEventTarget['onprogress']) => FetchAPI = (onProgress?) => (
    url: string,
    init: RequestInit
) => {
    return new Promise((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.open(init.method || 'get', url);

        if (init.headers) {
            const headers = init.headers as Record<string, string>;
            for (let k of Object.keys(headers)) {
                xhr.setRequestHeader(k, headers[k]);
            }
        }

        xhr.onload = (event) => {
            if (!event.target) {
                rej();
                return;
            }

            const response = event.target as XMLHttpRequest;

            const r = {
                status: response.status,
                json(): Promise<any> {
                    if (response.status === 204) {
                        return Promise.resolve();
                    }

                    try {
                        return Promise.resolve(JSON.parse(response.responseText));
                    } catch (e) {
                        return Promise.resolve(response.responseText);
                    }
                },
                clone: function () {
                    return this;
                },
            };
            // @ts-ignore
            res(r);
        };

        xhr.onerror = rej;

        xhr.onloadend = checkResponseForF5Reject;

        if (xhr.upload && onProgress) {
            // @ts-ignore
            xhr.upload.addEventListener('progress', onProgress);
        }

        // @ts-ignore
        xhr.send(init.body);
    });
};

const checkResponseForF5Reject: XMLHttpRequestEventTarget['onloadend'] = function () {
    const testedResponse = F5_REJECTED_RESPONSE_REGEX.exec(this.responseText);
    if (testedResponse && testedResponse[1]) {
        logF5Reject(testedResponse[1]);
    }
};

const logF5Reject = (requestId: string) => {
    Sentry.captureMessage('F5/AV rejects', {
        level: Sentry.Severity.Warning,
        tags: {
            requestId,
        },
    });
};
