import {FieldHelperProps, FieldHookConfig, FieldInputProps, FieldMetaProps, FormikContextType, useFormikContext} from 'formik';

export const isObject = (obj: any): obj is Object => obj !== null && typeof obj === 'object';

export function useFormikField<Val>(
    propsOrFieldName: string | FieldHookConfig<Val>
): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>, FormikContextType<Val>] {
    const contextHook = useFormikContext<Val>();

    const {getFieldProps, getFieldHelpers, getFieldMeta} = contextHook;

    const props: FieldHookConfig<Val> = isObject(propsOrFieldName)
        ? ((propsOrFieldName as unknown) as FieldHookConfig<Val>)
        : {name: propsOrFieldName as string};

    const {name: fieldName} = props;

    return [getFieldProps(props), getFieldMeta(fieldName), getFieldHelpers(fieldName), contextHook];
}
