import React from 'react';
import NumberFormat, {NumberFormatProps} from 'react-number-format';

export const Percent = (props: NumberFormatProps) => {
    if (!props.value) {
        return null;
    }

    return <NumberFormat displayType="text" thousandSeparator=" " decimalSeparator="," suffix={` %`} {...props} />;
};
