import {ErrorMessageDto} from '@finanso/api-common';
import React, {useEffect, useState} from 'react';
import {useMount} from 'react-use';
import {useLocation} from 'react-router-dom';
import {ADD_ERROR, ApiContextActions, CLEAR_ERRORS, clearErrors, END_LOADING, START_LOADING} from './ApiContext.actions';

export type ApiState = {
    loadingInProgress: number;
    error: ErrorMessageDto | undefined;
};

const initialState: ApiState = {
    loadingInProgress: 0,
    error: undefined,
};

interface ApiProviderProps {
    children: React.ReactNode;
    onInit?: (data: any) => void;
    fallback: React.ReactNode;
}

const ApiContext = React.createContext<ApiState | undefined>(undefined);
const ApiDispatchContext = React.createContext<((action: ApiContextActions) => void) | undefined>(undefined);

function apiReducer(state: ApiState, action: ApiContextActions): ApiState {
    switch (action.type) {
        case END_LOADING:
            return {
                ...state,
                loadingInProgress: Math.max(0, state.loadingInProgress - 1),
            };
        case START_LOADING:
            return {
                ...state,
                loadingInProgress: state.loadingInProgress + 1,
                error: undefined,
            };
        case ADD_ERROR:
            return {...state, error: action.payload};
        case CLEAR_ERRORS: {
            return {...state, error: undefined};
        }
        default: {
            return state;
        }
    }
}

function ApiProvider({children, onInit, fallback: Fallback}: ApiProviderProps) {
    const [state, dispatch] = React.useReducer(apiReducer, initialState);
    const [hasConfig, setHasConfig] = useState<boolean | undefined>(undefined);
    const location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [location]);

    useMount(async () => {
        await fetch(`${process.env.PUBLIC_URL}/config/config.json`)
            .then((response) => response.json())
            .then((data: any) => {
                if (onInit) {
                    try {
                        onInit(data);
                    } catch (e) {
                        console.warn(e);
                    }
                }
                setHasConfig(true);
            })
            .catch((e) => {
                console.warn(e);
                setHasConfig(false);
            });
    });

    return (
        <ApiContext.Provider value={state}>
            <ApiDispatchContext.Provider value={dispatch}>
                {hasConfig === undefined ? Fallback : hasConfig ? children : 'TODO - config not loaded'}
            </ApiDispatchContext.Provider>
        </ApiContext.Provider>
    );
}

function useApiState() {
    const context = React.useContext(ApiContext);
    if (context === undefined) {
        throw new Error('useApiState must be used within a ApiProvider');
    }
    return context;
}

function useApiDispatch() {
    const context = React.useContext(ApiDispatchContext);
    if (context === undefined) {
        throw new Error('useApiDispatch must be used within a ApiProvider');
    }
    return context;
}

export type ApiDispatchType = ReturnType<typeof useApiDispatch>;

export {ApiProvider, useApiState, useApiDispatch};
