import React from 'react';

export const CheckCircle = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 0C13.4584 0 0 13.4584 0 30C0 46.5416 13.4584 60 30 60C46.5416 60 60 46.5416 60 30C60 13.4584 46.5416 0 30 0ZM30 57.0024C15.1101 57.0024 2.99755 44.8899 2.99755 30C2.99755 15.1101 15.1101 2.99755 30 2.99755C44.8899 2.99755 57.0024 15.1101 57.0024 30C57.0024 44.8899 44.8899 57.0024 30 57.0024ZM26.354 34.1354L37.8548 22.6958C38.4421 22.1085 39.3842 22.1085 39.9715 22.6958C40.5465 23.283 40.5465 24.2251 39.9592 24.8124L27.394 37.3042C27.1003 37.5979 26.721 37.7447 26.3418 37.7447C25.9502 37.7447 25.571 37.5979 25.2773 37.3042L20.0285 32.0188C19.4535 31.4315 19.4535 30.4772 20.0408 29.9021C20.6281 29.3271 21.5701 29.3271 22.1574 29.9144L26.354 34.1354Z"
            fill="#7A113F"
        />
    </svg>
);
